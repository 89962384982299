import { type FetchError } from 'ofetch';
import BaseApiService from '~/services/api/BaseApiService';
import { apiErrorHandler } from '~/utils/forms/ErrorHandling';
import type {
    TaxRateResponse, TaxRatesOverview, TaxRatesOverviewResponse, WriteTaxRateDto,
} from '~/types/TaxRate';
import { TaxRateFactory } from '~/models/factories/TaxRateFactory';
import type { TaxRate } from '~/models/TaxRate';
import type { List } from '~/models/List';
import type { ListResponse } from '~/types/List';
import { ListFactory } from '~/models/factories/ListFactory';

export default class TaxRateService extends BaseApiService {
    private basePath = 'tax-rates';

    /**
     * @description Fetch single tax rate by tax rate id.
     * @param {number} taxRateId Identifier of the tax rate to fetch
     * @returns {Promise<TaxRate>} Promise with the TaxRate model as payload
     */
    async fetchTaxRate(taxRateId: number): Promise<TaxRate> {
        try {
            const response = await this.baseGet<TaxRateResponse>(
                `${this.createPath(this.basePath)}/${taxRateId}`,
            );

            return (new TaxRateFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch multiple tax rates depending on values in queryString.
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<TaxRatesOverview>} Promise with the TaxRatesOverview as payload
     */
    async fetchTaxRates(queryParameters: (Record<string, unknown> | null) = null): Promise<TaxRatesOverview> {
        try {
            const response = await this.baseGet<TaxRatesOverviewResponse>(
                this.createPath(this.basePath),
                queryParameters,
            );

            const data = (new TaxRateFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch list of tax rates.
     * @returns {Promise<List[]>} Promise with the List as payload
     */
    async fetchTaxRatesList(): Promise<List[]> {
        try {
            const response = await this.baseGet<ListResponse>(
                `${this.createPath(this.basePath)}/list`,
            );

            return (new ListFactory()).toModels(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Create a new tax rate.
     * @param {WriteTaxRateDto} data Form data to post
     * @returns {Promise<TaxRate>} Api response
     */
    async create(data: WriteTaxRateDto): Promise<TaxRate> {
        try {
            const response = await this.basePost<TaxRateResponse>(
                this.createPath(this.basePath),
                data,
            );

            return (new TaxRateFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Update a tax rate.
     * @param {number} taxRateId Id of the resource to save
     * @param {WriteTaxRateDto} data Form data to post
     * @returns {Promise<null>} Api response
     */
    update(taxRateId: number, data: WriteTaxRateDto): Promise<null> {
        try {
            return this.basePatch<null>(
                `${this.createPath(this.basePath)}/${taxRateId}`,
                data,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Delete a tax rate.
     * @param {number} taxRateId Id of the resource to delete
     * @returns {Promise<null>} Api response
     */
    delete(taxRateId: number): Promise<null> {
        try {
            return this.baseDelete(`${this.createPath(this.basePath)}/${taxRateId}`);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }
}
