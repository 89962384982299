export class Attachment {
    public attachedTo: string | null = null;

    public createdAt: string | null = null;

    public deletedAt: string | null = null;

    public filename: string | null = null;

    public id: number | null = null;

    public type: string | null = null;

    public typeName: string | null = null;

    public visibleOnExtranet = false;
}
