export const downloadFile = (file: string, fileName: string): void => {
    const fileURL = globalThis.URL.createObjectURL(new Blob([file]));
    const fileLink = document.createElement('a');

    fileLink.href = fileURL;
    fileLink.setAttribute('download', fileName);
    document.body.append(fileLink);

    fileLink.click();
    fileLink.remove();
};

export const toBase64 = (file: File): Promise<string | ArrayBuffer | null> => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.addEventListener('load', () => resolve(reader.result));
    reader.addEventListener('error', error => reject(error));
});

export const getFileName = (headers: Headers): string | null => headers.get('content-disposition')
    ?.split('filename=')[1].split(';')[0].replaceAll('"', '') || null;
