import { type FetchError } from 'ofetch';
import { ExtranetInvoiceFactory } from '~/models/factories/ExtranetInvoiceFactory';
import BaseApiService from '~/services/api/BaseApiService';
import { apiErrorHandler } from '~/utils/forms/ErrorHandling';
import type { ExtranetInvoicesOverview, ExtranetInvoicesOverviewResponse } from '~/types/Invoice';

export default class InvoiceService extends BaseApiService {
    private basePath = 'invoices';

    async fetchInvoices(queryParameters: (Record<string, unknown> | null) = null): Promise<ExtranetInvoicesOverview> {
        try {
            const response = await this.baseGet<ExtranetInvoicesOverviewResponse>(
                this.createPath(this.basePath),
                queryParameters,
            );

            const data = (new ExtranetInvoiceFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    async downloadInvoice(id: number): Promise<void> {
        try {
            await this.baseDownloadBlob(
                `${this.createPath(this.basePath)}/${id}/download`,
                {
                    headers: {
                        Accept: 'application/pdf',
                    },
                },
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }
}
