import { BaseFactory } from './BaseFactory';
import type { ReadInvoiceLogDto } from '~/types/InvoiceLog';
import { InvoiceLog } from '~/models/InvoiceLog';

export class InvoiceLogFactory extends BaseFactory<ReadInvoiceLogDto, InvoiceLog> {
    public toModel(dto: ReadInvoiceLogDto): InvoiceLog {
        const model = new InvoiceLog();

        model.createdAt = dto.createdAt;
        model.fullName = dto.fullName;
        model.id = Number(dto.id);
        model.invoiceId = Number(dto.invoiceId);
        model.message = dto.message;
        model.updatedAt = dto.updatedAt;
        model.userId = Number(dto.userId);

        return model;
    }
}
