import DateFormatUtils from '~/utils/DateFormatUtils';

export class Report {
    public createdAt: string | null = null;

    public file: string | null = null;

    public generated = false;

    public id: number | null = null;

    public title: string | null = null;

    public type: string | null = null;

    public updatedAt: string | null = null;

    public getCreatedAt(): string {
        return DateFormatUtils.dateFormatToDisplay(this?.createdAt?.split(' ')[0] ?? '');
    }
}
