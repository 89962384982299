import { type FetchError } from 'ofetch';
import BaseApiService from '~/services/api/BaseApiService';
import { apiErrorHandler } from '~/utils/forms/ErrorHandling';
import type {
    ReportResponse, ReportsOverview, ReportsOverviewResponse, WriteReportDto,
} from '~/types/Report';
import { ReportFactory } from '~/models/factories/ReportFactory';
import type { Report } from '~/models/Report';

export default class ReportService extends BaseApiService {
    private basePath = 'reports';

    /**
     * @description Download report file.
     * @param {number} reportId Identifier of the report to download
     * @returns {Promise<string>} Promise with the report CSV as payload
     */
    downloadReport(reportId: number): Promise<string> {
        try {
            return this.baseDownloadBlob<string>(
                `${this.createPath(this.basePath)}/${reportId}/download`,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch multiple reports depending on values in queryString.
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<ReportsOverview>} Promise with the ReportsOverview as payload
     */
    async fetchReports(queryParameters: (Record<string, unknown> | null) = null): Promise<ReportsOverview> {
        try {
            const response = await this.baseGet<ReportsOverviewResponse>(
                this.createPath(this.basePath),
                queryParameters,
            );

            const data = (new ReportFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Create a new report.
     * @param {WriteReportDto} data Form data to post
     * @returns {Promise<Report>} Api response
     */
    async create(data: WriteReportDto): Promise<Report> {
        try {
            const response = await this.basePost<ReportResponse>(
                `${this.createPath(this.basePath)}/generate`,
                data,
            );

            return (new ReportFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Delete a report.
     * @param {number} reportId Identifier of the report to delete
     * @returns {Promise<null>} Promise with an empty payload
     */
    delete(reportId: number): Promise<null> {
        try {
            return this.baseDelete(
                `${this.createPath(this.basePath)}/${reportId}`,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }
}
