import type { RegistrationType } from './RegistrationType';

export class Document {
    public active: boolean | null = false;

    public id: number | null = null;

    public name: string | null = null;

    public originalName: string | null = null;

    public registrationTypes: RegistrationType[] = [];

    public getRegistrationTypeNames(): string {
        return this.registrationTypes
            .map(registrationType => registrationType.name)
            .join(', ');
    }
}
