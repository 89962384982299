import { ExtranetInvoice } from '~/models/Invoice';
import { BaseFactory } from '~/models/factories/BaseFactory';
import type { ReadExtranetInvoiceDto } from '~/types/Invoice';

export class ExtranetInvoiceFactory extends BaseFactory<ReadExtranetInvoiceDto, ExtranetInvoice> {
    public toModel(dto: ReadExtranetInvoiceDto): ExtranetInvoice {
        const model = new ExtranetInvoice();

        model.id = dto.id;
        model.dateSent = dto.dateSent;
        model.totalAmount = dto.totalAmount;
        model.description = dto.description || '';
        model.invoiceNumber = Number(dto.invoiceNumber);
        model.paymentLink = dto.paymentLink || '';
        model.status = dto.status || '';

        return model;
    }
}
