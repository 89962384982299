import { BaseFactory } from './BaseFactory';
import type { ReadOverviewSignupDto } from '~/types/Signup';
import { OverviewSignup } from '~/models/OverviewSignup';

export class OverviewSignupFactory extends BaseFactory<ReadOverviewSignupDto, OverviewSignup> {
    /* eslint-disable-next-line max-statements */
    public toModel(dto: ReadOverviewSignupDto): OverviewSignup {
        const model = new OverviewSignup();

        model.companyName = dto.companyName;
        model.dateOfBirth = dto.dateOfBirth;
        model.finished = dto.finished;
        model.gender = dto.gender;
        model.id = dto.id;
        model.initials = dto.initials;
        model.lastName = dto.lastName;
        model.status = dto.status;
        model.type = dto.type;
        model.uniqueNumber = dto.uniqueNumber;
        model.uuid = dto.uuid;

        return model;
    }
}
