/* eslint-disable vue/sort-keys */

import { defineStore } from 'pinia';
import { DefaultState } from './application/state';
import Getters from './application/getters';
import Actions from './application/actions';
import Mutations from './application/mutations';

export const useApplicationStore = defineStore(
    'application',
    {
        state: DefaultState,
        getters: Getters,
        actions: {
            ...Actions,
            ...Mutations,
        },
    },
);
