import { BaseFactory } from './BaseFactory';
import type { ReadInvoiceTypeDto } from '~/types/InvoiceType';
import { InvoiceType } from '~/models/InvoiceType';

export class InvoiceTypeFactory extends BaseFactory<ReadInvoiceTypeDto, InvoiceType> {
    public toModel(dto: ReadInvoiceTypeDto): InvoiceType {
        const model = new InvoiceType();

        model.id = Number(dto.id);
        model.name = dto.name;

        return model;
    }
}
