import { type FetchError } from 'ofetch';
import BaseApiService from '~/services/api/BaseApiService';
import { apiErrorHandler } from '~/utils/forms/ErrorHandling';
import { CertificateFactory } from '~/models/factories/CertificateFactory';
import type { Certificate } from '~/models/Certificate';
import type {
    CertificateResponse,
    CertificatesOverview,
    CertificatesOverviewResponse,
} from '~/types/Certificate';
import type { PointResponse, ReadEssaysDto, ReadPointsDto } from '~/types/Points';

export default class CertificateService extends BaseApiService {
    private basePath = 'certificates';

    /**
     * @description Fetch single certificate by certificate id.
     * @param {number} certificateId Identifier of the certificate to fetch
     * @returns {Promise<Certificate>} Promise with the Certificate model as payload
     */
    async fetchCertificate(certificateId: number): Promise<Certificate> {
        try {
            const response = await this.baseGet<CertificateResponse>(
                `${this.createPath(this.basePath)}/${certificateId}`,
            );

            return (new CertificateFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch multiple certificates depending on values in queryString of logged in user.
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<CertificatesOverview>} Promise with the CertificatesOverview as payload
     */
    async fetchCertificates(queryParameters: (Record<string, unknown> | null) = null): Promise<CertificatesOverview> {
        try {
            const response = await this.baseGet<CertificatesOverviewResponse>(
                this.createPath(this.basePath),
                queryParameters,
            );

            const data = (new CertificateFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch points of a certificate by certificate id.
     * @param {number} certificateId Identifier of the certificate to fetch the points of
     * @returns {Promise<ReadEssaysDto | ReadPointsDto>} Promise with the ReadEssaysDto or ReadPointsDtoas payload
     */
    async fetchPointsById(certificateId: number): Promise<ReadEssaysDto | ReadPointsDto> {
        try {
            const response = await this.baseGet<PointResponse>(
                `${this.createPath(this.basePath)}/${certificateId}/points`,
            );

            return response.data;
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch multiple registrations depending on values in queryString of logged in user.
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<CertificatesOverview>} Promise with the CertificatesOverview as payload
     */
    async fetchRegistrations(queryParameters: (Record<string, unknown> | null) = null): Promise<CertificatesOverview> {
        try {
            const response = await this.baseGet<CertificatesOverviewResponse>(
                `${this.createPath(this.basePath)}/registrations`,
                queryParameters,
            );

            const data = (new CertificateFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }
}
