import { AddressFactory } from './AddressFactory';
import { BaseFactory } from './BaseFactory';
import type { ReadRelationAddressDto } from '~/types/RelationAddress';
import { RelationAddress } from '~/models/RelationAddress';

export class RelationAddressFactory extends BaseFactory<ReadRelationAddressDto, RelationAddress> {
    public toModel(dto: ReadRelationAddressDto): RelationAddress {
        const model = new RelationAddress();

        model.invoicePreferenceAddress = dto.invoicePreferenceAddress;

        if (dto.addresses) {
            model.addresses = (new AddressFactory()).toModels(dto.addresses);
        }

        return model;
    }
}
