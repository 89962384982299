export class RegistrationType {
    public durationInYears: number | null = null;

    public id: number | null = null;

    public isRegistration: boolean | null = false;

    public name: string | null = null;

    public numberColumn: string | null = null;

    public numberPrefix: string | null = null;

    public recertification: string | null = null;

    public type: string | null = null;
}

