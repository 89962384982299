import type { ChamberRegistrationType } from './ChamberRegistrationType';
import type { ThresholdAndCourse } from './ThresholdAndCourse';

export class Chamber {
    public code: string | null = null;

    public id: number | null = null;

    public name: string | null = null;

    public thresholdsAndCourses: ThresholdAndCourse[] = [];

    public rawName: string | null = null;

    public registrationType: ChamberRegistrationType | null = null;

    public registrationTypesId: number | null = null;

    public isRegistrationTypeARMT(): boolean {
        return Boolean(this.registrationType && this.registrationType.name === 'A-RMT');
    }

    public isRegistrationTypeKRMT(): boolean {
        return Boolean(this.registrationType && this.registrationType.name === 'K-RMT');
    }

    public getRegistrationTypeName(): string {
        return `${this.registrationType ? this.registrationType.name : ''}`;
    }
}
