import { BaseFactory } from './BaseFactory';
import type { ReadDiplomaDto } from '~/types/Diploma';
import { Diploma } from '~/models/Diploma';

export class DiplomaFactory extends BaseFactory<ReadDiplomaDto, Diploma> {
    public toModel(dto: ReadDiplomaDto): Diploma {
        const model = new Diploma();

        model.id = Number(dto.id);
        model.name = dto.name;

        return model;
    }
}
