import { BaseFactory } from './BaseFactory';
import { RoleFactory } from './RoleFactory';
import { RelationFactory } from './RelationFactory';
import { User } from '~/models/User';
import type { ReadUserDto } from '~/types/User';

export class UserFactory extends BaseFactory<ReadUserDto, User> {
    public toModel(dto: ReadUserDto): User {
        const model = new User();

        model.active = dto.active;
        model.email = dto.email;
        model.emailVerifiedAt = dto.emailVerifiedAt;
        model.id = Number(dto.id);

        if (dto.relation) {
            model.relation = (new RelationFactory()).toModel(dto.relation.data);
        }

        if (dto.roles) {
            model.roles = (new RoleFactory()).toModels(dto.roles.data);
        }

        return model;
    }
}
