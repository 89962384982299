import { type FetchError } from 'ofetch';
import type { Attachment } from '~/models/Attachment';
import { AttachmentFactory } from '~/models/factories/AttachmentFactory';
import BaseApiService from '~/services/api/BaseApiService';
import { apiErrorHandler } from '~/utils/forms/ErrorHandling';
import type {
    AttachmentResponse,
    AttachmentsOverview,
    AttachmentsOverviewResponse,
    WriteAttachmentDto,
} from '~/types/Attachment';

export default class AttachmentService extends BaseApiService {
    private basePath = 'attachments';

    /**
     * @description Download attachment file.
     * @param {number} attachmentId Identifier of the attachment to download
     * @returns {Promise<string>} Promise with the attachment as payload
     */
    async download(attachmentId: number): Promise<void> {
        try {
            await this.baseDownloadBlob(
                `${this.createPath(this.basePath)}/${attachmentId}`,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch multiple attachments depending on the parsed resourceId and resourcePath.
     * @param {number} resourceId ID of the resource to fetch attachments of
     * @param {string} resourcePath ID of the certificate to fetch attachments of
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<AttachmentsOverview>} Promise with the AttachmentsOverview as payload
     */
    async fetchAttachments(
        resourceId: number,
        resourcePath: string,
        queryParameters: (Record<string, unknown> | null) = null,
    ): Promise<AttachmentsOverview> {
        try {
            const response = await this.baseGet<AttachmentsOverviewResponse>(
                `${this.createPath(resourcePath)}/${resourceId}/${this.basePath}`,
                queryParameters,
            );

            const data = (new AttachmentFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Delete an attachment.
     * @param {WriteAttachmentDto} data Data to save
     * @returns {Promise<null>} Api response
     */
    create(data: WriteAttachmentDto): Promise<null> {
        try {
            return this.basePost<null>(
                this.createPath(this.basePath),
                data,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Delete an attachment.
     * @param {number} attachmentId Id of the resource to delete
     * @returns {Promise<null>} Api response
     */
    delete(attachmentId: number): Promise<null> {
        try {
            return this.baseDelete(`${this.createPath(this.basePath)}/${attachmentId}`);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Toggle visibilty on extranet state.
     * @param {number} attachmentId Identifier of the attachment to toggle the visibilty on extranet state of
     * @returns {Promise<null>} Promise with an empty payload
     */
    toggleVisibleOnExtranet(attachmentId: number): Promise<null> {
        try {
            return this.basePost<null>(
                `${this.createPath(this.basePath)}/${attachmentId}/toggle`,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Create and link an attachment.
     * @param {WriteAttachmentDto} writeAttachmentDto The resource to save
     * @returns {Promise<Attachment>} Api response
     */
    async uploadAgreement(writeAttachmentDto: WriteAttachmentDto): Promise<Attachment> {
        const formData = new FormData();
        formData.append('certificateAgreement', writeAttachmentDto.file);
        formData.append('model', writeAttachmentDto.model);
        formData.append('id', writeAttachmentDto.id);
        formData.append('visibleOnExtranet', writeAttachmentDto.visibleOnExtranet);

        const response = await this.basePost<AttachmentResponse>(
            `${this.createPath('certificates')}/${writeAttachmentDto.id}/uploadAgreement`,
            formData,
        );

        return (new AttachmentFactory()).toModel(response.data);
    }
}
