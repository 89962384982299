import { type FetchError } from 'ofetch';
import { UnlinkableMeasurementTypeEnum } from '~/enums/UnlinkableMeasurementTypeEnum';
import { UnlinkableMeasurementFactory } from '~/models/factories/UnlinkableMeasurementFactory';
import type { UnlinkableMeasurement } from '~/models/UnlinkableMeasurement';
import BaseApiService from '~/services/api/BaseApiService';
import { apiErrorHandler } from '~/utils/forms/ErrorHandling';
import type {
    ReadUnlinkableMeasurementCountDto,
    UnlinkableMeasurementResponse,
    UnlinkableMeasurementsOverview,
    UnlinkableMeasurementsOverviewResponse,
    WriteUnlinkableMeasurementDto,
} from '~/types/UnlinkableMeasurement';

export default class UnlinkableMeasurementService extends BaseApiService {
    private basePath = 'unlinkable-measurements';

    /**
     * @description Fetch single unlinkable measurement by id.
     * @param {number} unlinkableMeasurementId Identifier of the unlinkable measurement to fetch
     * @returns {Promise<UnlinkableMeasurement>} Promise with the UnlinkableMeasurement model as payload
     */
    async fetchUnlinkableMeasurement(unlinkableMeasurementId: number): Promise<UnlinkableMeasurement> {
        try {
            const response = await this.baseGet<UnlinkableMeasurementResponse>(
                `${this.createPath(this.basePath)}/${unlinkableMeasurementId}`,
            );

            return (new UnlinkableMeasurementFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch multiple unlinkable measurements depending on values in queryString.
     * @param {UnlinkableMeasurementTypeEnum} type type of the unlinkeable measurements to fetch
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<UnlinkableMeasurementsOverview>} Promise with the UnlinkableMeasurementsOverview as payload
     */
    async fetchUnlinkableMeasurements(
        type: UnlinkableMeasurementTypeEnum,
        queryParameters: (Record<string, unknown> | null) = null,
    ): Promise<UnlinkableMeasurementsOverview> {
        try {
            const response = await this.baseGet<UnlinkableMeasurementsOverviewResponse>(
                `${this.createPath(this.basePath)}${type === UnlinkableMeasurementTypeEnum.ERROR ? '/errors' : ''}`,
                queryParameters,
            );

            const data = (new UnlinkableMeasurementFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch Unlinkable Measurement counts per status.
     * @returns {Promise<ReadUnlinkableMeasurementCountDto[]>}
     * Promise with the ReadUnlinkableMeasurementCountDto[] as payload
     */
    fetchCounts(): Promise<ReadUnlinkableMeasurementCountDto> {
        try {
            return this.baseGet<ReadUnlinkableMeasurementCountDto>(
                `${this.createPath(this.basePath)}/counters`,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Link a relation.
     * @param {number} unlinkableMeasurementId Id of the resource to save
     * @param {WriteUnlinkableMeasurementDto} data Form data to post
     * @returns {Promise<null>} Api response
     */
    linkRelation(unlinkableMeasurementId: number, data: WriteUnlinkableMeasurementDto): Promise<null> {
        try {
            return this.basePost<null>(
                `${this.createPath(this.basePath)}/${unlinkableMeasurementId}/process`,
                data,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Delete a unlinkable measurement.
     * @param {number} unlinkableMeasurementId Id of the resource to delete
     * @returns {Promise<null>} Api response
     */
    delete(unlinkableMeasurementId: number): Promise<null> {
        try {
            return this.baseDelete(`${this.createPath(this.basePath)}/${unlinkableMeasurementId}`);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }
}
