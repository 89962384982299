import { Role } from '../Role';
import { BaseFactory } from './BaseFactory';
import type { ReadRoleDto } from '~/types/Role';

export class RoleFactory extends BaseFactory<ReadRoleDto, Role> {
    public toModel(dto: ReadRoleDto): Role {
        const model = new Role();

        model.id = Number(dto.id);
        model.name = dto.name;

        return model;
    }
}
