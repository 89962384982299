import type { TaxRate } from './TaxRate';

export class Product {
    public code: string | null = null;

    public id: number | null = null;

    public ledgerNumber: string | null = null;

    public name: string | null = null;

    public price: number | null = null;

    public taxRate: TaxRate | null = null;

    public taxRateId: number | null = null;
}
