import type { Product } from './Product';

export class InvoiceLine {
    public amount: number | null = null;

    public id: number | null = null;

    public invoiceId: number | null = null;

    public product: Product | null = null;

    public productCode: string | null = null;

    public productId: number | null = null;

    public productName: string | null = null;

    public productPrice: number | null = null;

    public subtotal: number | null = null;

    public taxRate: number | null = null;

    public getTotal(): number {
        return (Number(this.productPrice) * Number(this.amount)) * ((Number(this.taxRate) / 100) + 1);
    }
}
