import { UnlinkableMeasurement } from '../UnlinkableMeasurement';
import { BaseFactory } from './BaseFactory';
import type { ReadUnlinkableMeasurementDto } from '~/types/UnlinkableMeasurement';

export class UnlinkableMeasurementFactory extends BaseFactory<ReadUnlinkableMeasurementDto, UnlinkableMeasurement> {
    public toModel(dto: ReadUnlinkableMeasurementDto): UnlinkableMeasurement {
        const model = new UnlinkableMeasurement();

        model.client = dto.client;
        model.content = dto.content;
        model.date = dto.date;
        model.error = dto.error;
        model.id = Number(dto.id);
        model.level = Number(dto.level);
        model.type = dto.type;

        return model;
    }
}
