import { type FetchError } from 'ofetch';
import BaseApiService from '~/services/api/BaseApiService';
import { apiErrorHandler } from '~/utils/forms/ErrorHandling';
import type {
    CompaniesOverview,
    CompaniesOverviewResponse,
} from '~/types/Company';
import { CompanyFactory } from '~/models/factories/CompanyFactory';

export default class CompanyService extends BaseApiService {
    public static basePath = 'companies';

    /**
     * @description Fetch multiple companies depending on values in queryString of logged in user.
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<CompaniesOverview>} Promise with the CompaniesOverview as payload
     */
    async fetchCompanies(queryParameters: (Record<string, unknown> | null) = null): Promise<CompaniesOverview> {
        try {
            const response = await this.baseGet<CompaniesOverviewResponse>(
                this.createPath(CompanyService.basePath),
                queryParameters,
            );
            const companyData = (new CompanyFactory()).toModels(response.data);
            const metaData = response.meta;

            return {
                data: companyData,
                meta: metaData,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }
}
