import DateFormatUtils from '~/utils/DateFormatUtils';

export class CertificateProlongate {
    public certificateNumber: string | null = null;

    public chamberCode: string | null = null;

    public checked: boolean | null = true;

    public id: number | null = null;

    public pointsAchieved: number | null = null;

    public newEndDate: string | null = null;

    public newStartDate: string | null = null;

    public pointsRequired: number | null = null;

    public relationName: string | null = null;

    public setChecked(checked: boolean): void {
        this.checked = checked;
    }

    public getNewEndDate(): string {
        return DateFormatUtils.dateFormatToDisplay(this.newEndDate ?? '');
    }

    public getNewStartDate(): string {
        return DateFormatUtils.dateFormatToDisplay(this.newStartDate ?? '');
    }
}
