export class ThresholdAndCourse {
    public courses: number[] = [];

    public description: string | null = null;

    public id: number | null = null;

    public key: string | null = null;

    public level: number | null = null;

    public sort: number | null = null;

    public threshold: number | null = null;
}
