import type { CompanyType } from './CompanyType';
import type { Remark } from './Remark';

export class Company {
    public active: boolean | null = null;

    public city: string | null = null;

    public cocNumber: string | null = null;

    public companyType: CompanyType | null = null;

    public companyTypeId: number | null = null;

    public email: string | null = null;

    public houseNumber: string | null = null;

    public houseNumberPostfix: string | null = null;

    public id: number | null = null;

    public lat: string | null = null;

    public lng: string | null = null;

    public mailbox: string | null = null;

    public mailboxCity: string | null = null;

    public mailboxPostal: string | null = null;

    public name: string | null = null;

    public phone: string | null = null;

    public postal: string | null = null;

    public province: string | null = null;

    public remarks: Remark[] = [];

    public street: string | null = null;

    public website: string | null = null;

    public getActive(): string {
        return this.active ? 'Ja' : 'Nee';
    }
}
