import { type FetchError } from 'ofetch';
import BaseApiService from '~/services/api/BaseApiService';
import { apiErrorHandler } from '~/utils/forms/ErrorHandling';
import type {
    DocumentResponse, DocumentsOverview, DocumentsOverviewResponse, WriteDocumentDto,
} from '~/types/Document';
import { DocumentFactory } from '~/models/factories/DocumentFactory';
import type { Document } from '~/models/Document';

export default class DocumentService extends BaseApiService {
    private basePath = 'documents';

    /**
     * @description Download document file.
     * @param {number} documentId Identifier of the document to download
     * @returns {Promise<void>} Promise with the document as payload
     */
    async download(documentId: number): Promise<void> {
        try {
            await this.baseDownloadBlob(
                `${this.createPath(this.basePath)}/${documentId}/download`,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch single document by document id.
     * @param {number} documentId Identifier of the document to fetch
     * @returns {Promise<Document>} Promise with the Document model as payload
     */
    async fetchDocument(documentId: number): Promise<Document> {
        try {
            const response = await this.baseGet<DocumentResponse>(
                `${this.createPath(this.basePath)}/${documentId}`,
            );

            return (new DocumentFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch multiple documents depending on values in queryString.
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<DocumentsOverview>} Promise with the DocumentsOverview as payload
     */
    async fetchDocuments(queryParameters: (Record<string, unknown> | null) = null): Promise<DocumentsOverview> {
        try {
            const response = await this.baseGet<DocumentsOverviewResponse>(
                this.createPath(this.basePath),
                queryParameters,
            );

            const data = (new DocumentFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Create a new document.
     * @param {WriteDocumentDto} data Form data to post
     * @returns {Promise<Document>} Api response
     */
    async create(data: WriteDocumentDto): Promise<Document> {
        const response = await this.basePost<DocumentResponse>(
            this.createPath(this.basePath),
            data,
        );

        return (new DocumentFactory()).toModel(response.data);
    }

    /**
     * @description Update a document.
     * @param {number} documentId Id of the resource to save
     * @param {WriteDocumentDto} data Form data to post
     * @returns {Promise<null>} Api response
     */
    update(documentId: number, data: WriteDocumentDto): Promise<null> {
        return this.basePost<null>(
            `${this.createPath(this.basePath)}/${documentId}`,
            data,
        );
    }

    /**
     * @description Delete a document.
     * @param {number} documentId Id of the resource to delete
     * @returns {Promise<null>} Api response
     */
    delete(documentId: number): Promise<null> {
        try {
            return this.baseDelete(`${this.createPath(this.basePath)}/${documentId}`);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }
}
