import { type FetchError } from 'ofetch';
import BaseApiService from '~/services/api/BaseApiService';
import { apiErrorHandler } from '~/utils/forms/ErrorHandling';
import type {
    CoursesOverview,
    CoursesOverviewResponse,
} from '~/types/Course';
import { CourseFactory } from '~/models/factories/CourseFactory';

export default class CourseService extends BaseApiService {
    private basePath = 'courses';

    /**
     * @description Fetch multiple courses depending on values in queryString of logged in user.
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<CoursesOverview>} Promise with the CoursesOverview as payload
     */
    async fetchCourses(queryParameters: (Record<string, unknown> | null) = null): Promise<CoursesOverview> {
        try {
            const response = await this.baseGet<CoursesOverviewResponse>(
                this.createPath(this.basePath),
                queryParameters,
            );

            const data = (new CourseFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }
}
