import { type FetchError } from 'ofetch';
import BaseApiService from '~/services/api/BaseApiService';
import { apiErrorHandler } from '~/utils/forms/ErrorHandling';
import type {
    MailMessageResponse,
    MailMessagesOverview,
    MailMessagesOverviewResponse,
    PreviewMailMessageResponse,
    WriteMailMessageDto,
} from '~/types/MailMessage';
import { MailMessageFactory } from '~/models/factories/MailMessageFactory';
import type { PreviewMailMessage } from '~/models/PreviewMailMessage';
import type { MailMessage } from '~/models/MailMessage';

export default class MailMessageService extends BaseApiService {
    private basePath = 'mail-messages';

    /**
     * @description Fetch single mail message by mail message id for preview.
     * @param {number} mailMessageId Identifier of the mail message to fetch
     * @returns {Promise<PreviewMailMessage>} Promise with the MailMessage model as payload
     */
    async fetchMailMessagePreview(mailMessageId: number): Promise<PreviewMailMessage> {
        try {
            const response = await this.baseGet<PreviewMailMessageResponse>(
                `${this.createPath(this.basePath)}/${mailMessageId}/preview`,
            );

            return (new MailMessageFactory()).toPreview(response);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch multiple tax rates depending on values in queryString.
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<MailMessagesOverview>} Promise with the MailMessagesOverview as payload
     */
    async fetchMailMessages(queryParameters: (Record<string, unknown> | null) = null): Promise<MailMessagesOverview> {
        try {
            const response = await this.baseGet<MailMessagesOverviewResponse>(
                this.createPath(this.basePath),
                queryParameters,
            );

            const data = (new MailMessageFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch single mail message by mail message id.
     * @param {number} mailMessageId Identifier of the tax rate to fetch
     * @returns {Promise<MailMessageResponse>} Promise with the MailMessage model as payload
     */
    async fetchMailMessage(mailMessageId: number): Promise<MailMessage> {
        try {
            const response = await this.baseGet<MailMessageResponse>(
                `${this.createPath(this.basePath)}/${mailMessageId}`,
            );

            return (new MailMessageFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Update a mail message.
     * @param {number} mailMessageId Id of the resource to save
     * @param {WriteMailMessageDto} data Form data to post
     * @returns {Promise<null>} Api response
     */
    update(mailMessageId: number, data: WriteMailMessageDto): Promise<null> {
        return this.basePut<null>(
            `${this.createPath(this.basePath)}/${mailMessageId}`,
            data,
        );
    }
}
