import type { Breadcrumb } from '~/models/Breadcrumb';

export type ApplicationState = {
    breadcrumbs: Breadcrumb[];
    routeNamespace: string;
    saveUrl: string;
};

export const defaultStateData = (): ApplicationState => ({
    breadcrumbs: [],
    routeNamespace: '',
    saveUrl: '',
});

export const DefaultState = (): ApplicationState => defaultStateData();

export type State = ReturnType<typeof DefaultState>;
