import type { MailMessageDynamicField } from './MailMessageDynamicField';

export class MailMessage {
    public id: number | null = null;

    public mandatoryMail: boolean | null = false;

    public name: string | null = null;

    public subject: string | null = null;

    public body: string | null = null;

    public mailTemplateId: number | null = null;

    public dynamicFields: MailMessageDynamicField[] | null = [];
}
