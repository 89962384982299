import { GET_BREADCRUMBS, GET_ROUTE_NAMESPACE } from './-getter-types';
import { getBreadcumbTitle } from '~/utils/BreadcrumbUtils';
import type { Breadcrumb } from '~/models/Breadcrumb';
import { useApplicationStore } from '~/stores/application';

const Getters = {
    [GET_BREADCRUMBS]: (): Breadcrumb[] => {
        const applicationStore = useApplicationStore();

        return applicationStore.breadcrumbs.map((item: Breadcrumb) => ({
            ...item,
            name: getBreadcumbTitle(item.name),
        }));
    },
    [GET_ROUTE_NAMESPACE]: (): string => {
        const applicationStore = useApplicationStore();

        return applicationStore.routeNamespace;
    },
};

export default Getters;
