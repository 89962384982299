import { BaseFactory } from './BaseFactory';
import type { ReadExactLogDto } from '~/types/ExactLog';
import { ExactLog } from '~/models/ExactLog';

export class ExactLogFactory extends BaseFactory<ReadExactLogDto, ExactLog> {
    public toModel(dto: ReadExactLogDto): ExactLog {
        const model = new ExactLog();

        model.content = dto.content;
        model.createdAt = dto.createdAt;
        model.id = Number(dto.id);
        model.method = dto.method;
        model.requestUri = dto.requestUri;
        model.responseStatuscode = Number(dto.responseStatuscode);
        model.responseContent = dto.responseContent;
        model.updatedAt = dto.updatedAt;

        return model;
    }
}
