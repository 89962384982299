import { BaseFactory } from './BaseFactory';
import { ThresholdAndCourseFactory } from './ThresholdAndCourseFactory';
import { ChamberRegistrationTypeFactory } from './ChamberRegistrationTypeFactory';
import { Chamber } from '~/models/Chamber';
import type { ReadChamberDto } from '~/types/Chamber';

export class ChamberFactory extends BaseFactory<ReadChamberDto, Chamber> {
    /* eslint-disable-next-line max-statements */
    public toModel(dto: ReadChamberDto): Chamber {
        const model = new Chamber();

        model.code = dto.code;
        model.id = Number(dto.id);
        model.name = dto.name;
        model.rawName = dto.rawName;

        if (dto.thresholdsAndCourses) {
            model.thresholdsAndCourses = (new ThresholdAndCourseFactory()).toModels(dto.thresholdsAndCourses.data);
        }

        if (dto.registrationType) {
            model.registrationType = (new ChamberRegistrationTypeFactory()).toModel(dto.registrationType.data);
        }

        model.registrationTypesId = Number(dto.registrationTypesId);

        return model;
    }
}
