import type { ReadSignupCertificateDto } from '~/types/Certificate';
import { SignupCertificate } from '~/models/SignupCertificate';
import { BaseFactory } from '~/models/factories/BaseFactory';

export class SignupCertificateFactory extends BaseFactory<ReadSignupCertificateDto, SignupCertificate> {
    public toModel(dto: ReadSignupCertificateDto): SignupCertificate {
        const model = new SignupCertificate();

        model.id = Number(dto.id);
        model.name = dto.name;

        return model;
    }
}
