import { BaseFactory } from './BaseFactory';
import { InvoiceLineFactory } from './InvoiceLineFactory';
import { InvoiceTypeFactory } from './InvoiceTypeFactory';
import { InvoiceStatusFactory } from './InvoiceStatusFactory';
import { RelationFactory } from './RelationFactory';
import { InvoiceLogFactory } from './InvoiceLogFactory';
import { Invoice } from '~/models/Invoice';
import type { ReadInvoiceDto, WriteInvoiceDto } from '~/types/Invoice';
import { InvoiceActionEnum } from '~/enums/InvoiceActionEnum';
import { AddressTypeEnum } from '~/enums/AddressTypeEnum';
import type { WriteInvoiceLineDto } from '~/types/InvoiceLine';

export class InvoiceFactory extends BaseFactory<ReadInvoiceDto, Invoice> {
    /* eslint-disable-next-line max-statements */
    public toModel(dto: ReadInvoiceDto): Invoice {
        const model = new Invoice();

        model.accountingPeriod = Number(dto.accountingPeriod);
        model.address = dto.address || '';
        model.addressType = dto.addressType;
        model.date = dto.date;
        model.dateSent = dto.dateSent;
        model.daysPastDue = Number(dto.daysPastDue ?? 0);
        model.deletedAt = dto.deletedAt;
        model.queuedAt = dto.queuedAt;
        model.description = dto.description || '';
        model.id = Number(dto.id);
        model.invoiceNumber = Number(dto.invoiceNumber);

        if (dto.invoiceLines) {
            model.invoiceLines = (new InvoiceLineFactory()).toModels(dto.invoiceLines.data);
        }

        if (dto.invoiceLogs) {
            model.invoiceLogs = (new InvoiceLogFactory()).toModels(dto.invoiceLogs.data);
        }

        if (dto.invoiceStatus) {
            model.invoiceStatus = (new InvoiceStatusFactory()).toModel(dto.invoiceStatus.data);
        }

        model.invoiceStatusId = Number(dto.invoiceStatusId);

        if (dto.invoiceType) {
            model.invoiceType = (new InvoiceTypeFactory()).toModel(dto.invoiceType.data);
        }

        model.invoiceTypeId = dto.invoiceTypeId ? Number(dto.invoiceTypeId) : null;

        if (dto.relation) {
            model.relation = (new RelationFactory()).toModel(dto.relation.data);
        }

        model.relationId = Number(dto.relationId);
        model.reminderSent = dto.reminderSent;
        model.sendDate = dto.sendDate;
        model.subtotal = Number(dto.subtotal);
        model.tax = Number(dto.tax);
        model.total = Number(dto.total);

        return model;
    }

    public toWriteDto(invoice: Invoice): WriteInvoiceDto {
        const dto: WriteInvoiceDto = {
            accountingPeriod: Number(invoice.accountingPeriod),
            action: InvoiceActionEnum.SAVE,
            address: String(invoice.address),
            addressType: invoice.addressType ?? AddressTypeEnum.COMPANY,
            date: String(invoice.date),
            description: String(invoice.description),
            id: invoice.id ? Number(invoice.id) : null,
            invoiceLines: invoice.invoiceLines
                .map((invoiceLine): WriteInvoiceLineDto => (new InvoiceLineFactory()).toWriteDto(invoiceLine)),
            invoiceStatusId: Number(invoice.invoiceStatusId),
            relationId: Number(invoice.relationId),
            sendDate: invoice.sendDate,
            subtotal: Number(invoice.subtotal),
            taxAmount: Number(invoice.tax),
            total: Number(invoice.total),
        };

        return dto;
    }
}
