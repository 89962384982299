import revive_payload_client_sJNReFmiej from "/usr/local/jenkins/workspace/appvastgoedcertnl/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_stylelint@16.10.0_typescript@5.6.3_vite@5.4.9/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_hMmZqKswqB from "/usr/local/jenkins/workspace/appvastgoedcertnl/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_stylelint@16.10.0_typescript@5.6.3_vite@5.4.9/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_MlYTfGOIPR from "/usr/local/jenkins/workspace/appvastgoedcertnl/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_stylelint@16.10.0_typescript@5.6.3_vite@5.4.9/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _09_vuex_pinia_dispatch_backwards_compatibility_plugin_QtsS8mwe4r from "/usr/local/jenkins/workspace/appvastgoedcertnl/plugins/09.vuex-pinia-dispatch-backwards-compatibility-plugin.ts";
import payload_client_zasM3UgviJ from "/usr/local/jenkins/workspace/appvastgoedcertnl/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_stylelint@16.10.0_typescript@5.6.3_vite@5.4.9/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_YisvuO5jTr from "/usr/local/jenkins/workspace/appvastgoedcertnl/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_stylelint@16.10.0_typescript@5.6.3_vite@5.4.9/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_0MiECVLEzK from "/usr/local/jenkins/workspace/appvastgoedcertnl/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_stylelint@16.10.0_typescript@5.6.3_vite@5.4.9/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_ONkN04XHcG from "/usr/local/jenkins/workspace/appvastgoedcertnl/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_stylelint@16.10.0_typescript@5.6.3_vite@5.4.9/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_z8NwqV8kGi from "/usr/local/jenkins/workspace/appvastgoedcertnl/node_modules/.pnpm/@pinia+nuxt@0.5.3_typescript@5.6.3_vue@3.5.12/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/usr/local/jenkins/workspace/appvastgoedcertnl/.nuxt/components.plugin.mjs";
import prefetch_client_xRbLwzuZ2i from "/usr/local/jenkins/workspace/appvastgoedcertnl/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_stylelint@16.10.0_typescript@5.6.3_vite@5.4.9/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_criFr2u97m from "/usr/local/jenkins/workspace/appvastgoedcertnl/node_modules/.pnpm/@sidebase+nuxt-auth@0.8.1_next-auth@4.21.1/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import sentry_client_vJFlzJ5xZw from "/usr/local/jenkins/workspace/appvastgoedcertnl/node_modules/.pnpm/@sentry+nuxt@8.34.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.26.0_@opentelemetry+instru_n33mkprdwuingu5bwuuooo3s5a/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/usr/local/jenkins/workspace/appvastgoedcertnl/.nuxt/sentry-client-config.mjs";
import _01_backend_fetch_plugin_yW8q2GdTHp from "/usr/local/jenkins/workspace/appvastgoedcertnl/plugins/01.backend-fetch-plugin.ts";
import _02_auth_can_not_directive_plugin_A0Cx9k3g5u from "/usr/local/jenkins/workspace/appvastgoedcertnl/plugins/02.auth-can-not-directive-plugin.ts";
import _03_auth_can_directive_plugin_0j38mA4Ik6 from "/usr/local/jenkins/workspace/appvastgoedcertnl/plugins/03.auth-can-directive-plugin.ts";
import _04_route_namespace_mixin_plugin_OTju9iF4LS from "/usr/local/jenkins/workspace/appvastgoedcertnl/plugins/04.route-namespace-mixin-plugin.ts";
import _05_provide_saveurl_plugin_qQD6sRPhal from "/usr/local/jenkins/workspace/appvastgoedcertnl/plugins/05.provide-saveurl-plugin.ts";
import _06_nuxt_fetch_hook_backwards_compatibility_plugin_uPCNGOQbXz from "/usr/local/jenkins/workspace/appvastgoedcertnl/plugins/06.nuxt-fetch-hook-backwards-compatibility-plugin.ts";
import _07_subscribe_data_grid_filters_changed_pinia_plugin_p2NWCvhmIz from "/usr/local/jenkins/workspace/appvastgoedcertnl/plugins/07.subscribe-data-grid-filters-changed-pinia-plugin.ts";
import _08_form_paths_mixin_plugin_4NdMNYmQQP from "/usr/local/jenkins/workspace/appvastgoedcertnl/plugins/08.form-paths-mixin-plugin.ts";
export default [
  revive_payload_client_sJNReFmiej,
  unhead_hMmZqKswqB,
  router_MlYTfGOIPR,
  _09_vuex_pinia_dispatch_backwards_compatibility_plugin_QtsS8mwe4r,
  payload_client_zasM3UgviJ,
  navigation_repaint_client_YisvuO5jTr,
  check_outdated_build_client_0MiECVLEzK,
  chunk_reload_client_ONkN04XHcG,
  plugin_vue3_z8NwqV8kGi,
  components_plugin_KR1HBZs4kY,
  prefetch_client_xRbLwzuZ2i,
  plugin_criFr2u97m,
  sentry_client_vJFlzJ5xZw,
  sentry_client_config_o34nk2sJbg,
  _01_backend_fetch_plugin_yW8q2GdTHp,
  _02_auth_can_not_directive_plugin_A0Cx9k3g5u,
  _03_auth_can_directive_plugin_0j38mA4Ik6,
  _04_route_namespace_mixin_plugin_OTju9iF4LS,
  _05_provide_saveurl_plugin_qQD6sRPhal,
  _06_nuxt_fetch_hook_backwards_compatibility_plugin_uPCNGOQbXz,
  _07_subscribe_data_grid_filters_changed_pinia_plugin_p2NWCvhmIz,
  _08_form_paths_mixin_plugin_4NdMNYmQQP
]