import type { DirectiveBinding } from 'vue';

// Replace HTMLElement with comment node.
export const replaceWithEmptyElement = (
    /* eslint-disable @typescript-eslint/no-explicit-any */
    element: any,
    binding: DirectiveBinding<any>,
    vnode: globalThis.VNode<any, any, {
        [key: string]: any;
    }>,
    /* eslint-enable @typescript-eslint/no-explicit-any */
) => {
    const comment = document.createComment(' ');
    Object.defineProperty(comment, 'setAttribute', {
        value: () => ({}),
    });
    vnode.el = comment;
    binding.instance = null;

    if (element.parentNode) {
        element.parentNode.replaceChild(comment, element);
    }
};
