export class LevelOfMeasurementsStatusText {
    public cmsTitle: string | null = null;

    public id: number | null = null;

    public key: string | null = null;

    public text: string | null = null;

    public title: string | null = null;
}
