import { BaseFactory } from './BaseFactory';
import type { ReadCertificateProlongateDto } from '~/types/CertificateProlongate';
import { CertificateProlongate } from '~/models/CertificateProlongate';

export class CertificateProlongateFactory extends BaseFactory<ReadCertificateProlongateDto, CertificateProlongate> {
    public toModel(dto: ReadCertificateProlongateDto): CertificateProlongate {
        const model = new CertificateProlongate();

        model.certificateNumber = dto.certificateNumber;
        model.chamberCode = dto.chamberCode;
        model.id = Number(dto.id);
        model.newEndDate = dto.endDate;
        model.newStartDate = dto.startDate;
        model.pointsAchieved = Number(dto.pointsAchieved);
        model.pointsRequired = Number(dto.pointsRequired);
        model.relationName = dto.relationName || null;

        return model;
    }
}
