import { type FetchError } from 'ofetch';
import type { ExactStatus } from '~/models/ExactStatus';
import { ExactLogFactory } from '~/models/factories/ExactLogFactory';
import { ExactStatusFactory } from '~/models/factories/ExactStatusFactory';
import BaseApiService from '~/services/api/BaseApiService';
import { apiErrorHandler } from '~/utils/forms/ErrorHandling';
import type { ExactLogsOverview, ExactLogsOverviewResponse } from '~/types/ExactLog';
import type { ExactStatusResponse } from '~/types/ExactStatus';

export default class ExactService extends BaseApiService {
    private basePath = 'exact';

    /**
     * @description Fetch multiple exact logs depending on values in queryString.
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<ExactLogsOverview>} Promise with the ExactLogsOverview as payload
     */
    async fetchLogs(queryParameters: (Record<string, unknown> | null) = null): Promise<ExactLogsOverview> {
        try {
            const response = await this.baseGet<ExactLogsOverviewResponse>(
                `${this.createPath(this.basePath)}/logs`,
                queryParameters,
            );

            const data = (new ExactLogFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch exact status.
     * @returns {Promise<ExactStatus>} Promise with the ExactStatusResponse as payload
     */
    async fetchStatus(): Promise<ExactStatus> {
        try {
            const response = await this.baseGet<ExactStatusResponse>(
                `${this.createPath(this.basePath)}/status`,
            );

            return (new ExactStatusFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch exact status.
     * @returns {Promise<ExactStatus>} Promise with the ExactStatusResponse as payload
     */
    async refreshToken(): Promise<ExactStatus> {
        try {
            const response = await this.basePost<ExactStatusResponse>(
                `${this.createPath(this.basePath)}/refresh`,
            );

            return (new ExactStatusFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }
}
