import { BaseFactory } from './BaseFactory';
import type { ReadListDto } from '~/types/List';
import { List } from '~/models/List';

export class ListFactory extends BaseFactory<ReadListDto, List> {
    public toModel(dto: ReadListDto): List {
        const model = new List();

        model.id = Number(dto.id);
        model.name = dto.name;

        return model;
    }
}
