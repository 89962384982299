import { BaseFactory } from './BaseFactory';
import type { ReadInvoiceStatusDto } from '~/types/InvoiceStatus';
import { InvoiceStatus } from '~/models/InvoiceStatus';

export class InvoiceStatusFactory extends BaseFactory<ReadInvoiceStatusDto, InvoiceStatus> {
    public toModel(dto: ReadInvoiceStatusDto): InvoiceStatus {
        const model = new InvoiceStatus();

        model.id = Number(dto.id);
        model.name = dto.name;

        return model;
    }
}
