import { BaseFactory } from './BaseFactory';
import type { ReadDynamicFieldsDto } from '~/types/MailMessage';
import { MailMessageDynamicField } from '~/models/MailMessageDynamicField';

export class MailMessageDynamicFieldsFactory extends BaseFactory<ReadDynamicFieldsDto, MailMessageDynamicField> {
    public toModel(dto: ReadDynamicFieldsDto): MailMessageDynamicField {
        const model = new MailMessageDynamicField();

        model.key = dto.key;
        model.object = dto.object;
        model.property = dto.property;
        model.testValue = dto.testValue;

        return model;
    }
}
