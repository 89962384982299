import {
    SET_DATA_GRID,
    SET_FILTER,
    SET_FILTERS_CHANGED,
    SET_ITEMS,
    SET_LOADING,
    SET_ORDER_BY,
    SET_PAGE,
    SET_PAGINATION,
    SET_PARAMETERS,
    SET_PER_PAGE,
    SET_QUERY_STRING,
    SET_SORT_DESCENDING,
} from './-mutation-types';
import { defaultStoreValues } from '~/stores/data-grids/state';
import { useDataGridsStore } from '~/stores/data-grids';
import type { PaginationResponse } from '~/types/Meta';

const Mutations = {
    [SET_DATA_GRID](identifier: string) {
        const dataGridsStore = useDataGridsStore();
        if (identifier in dataGridsStore.dataGrids === false) {
            dataGridsStore.dataGrids = {
                ...dataGridsStore.dataGrids,
                [identifier]: {
                    ...defaultStoreValues,
                },
            };
        }
    },
    [SET_FILTER]({ filterProperty, filterValue, identifier }: {
        filterProperty: string;
        filterValue: string | number;
        identifier: string;
    }) {
        const dataGridsStore = useDataGridsStore();
        dataGridsStore.dataGrids = {
            ...dataGridsStore.dataGrids,
            [identifier]: {
                ...dataGridsStore.dataGrids[identifier],
                filters: filterValue ? {
                    ...dataGridsStore.dataGrids[identifier].filters,
                    [filterProperty]: filterValue,
                } : {},
                page: 1,
            },
        };
    },
    [SET_FILTERS_CHANGED](identifier: string) {
        const dataGridsStore = useDataGridsStore();
        dataGridsStore.dataGrids = {
            ...dataGridsStore.dataGrids,
            [identifier]: {
                ...dataGridsStore.dataGrids[identifier],
                filtersChanged: Date.now(),
            },
        };
    },
    [SET_ITEMS]({ identifier, items }: {
        identifier: string;
        items: unknown;
    }) {
        const dataGridsStore = useDataGridsStore();
        dataGridsStore.dataGrids = {
            ...dataGridsStore.dataGrids,
            [identifier]: {
                ...dataGridsStore.dataGrids[identifier],
                items,
            },
        };
    },
    [SET_LOADING]({ identifier, loading }: {
        identifier: string;
        loading: boolean;
    }) {
        const dataGridsStore = useDataGridsStore();
        dataGridsStore.dataGrids = {
            ...dataGridsStore.dataGrids,
            [identifier]: {
                ...dataGridsStore.dataGrids[identifier],
                loading,
            },
        };
    },
    [SET_ORDER_BY]({ identifier, orderBy }: {
        identifier: string;
        orderBy: string | string[] | null;
    }) {
        const dataGridsStore = useDataGridsStore();
        dataGridsStore.dataGrids = {
            ...dataGridsStore.dataGrids,
            [identifier]: {
                ...dataGridsStore.dataGrids[identifier],
                orderBy,
            },
        };
    },
    [SET_PAGE]({ identifier, pageNumber }: {
        identifier: string;
        pageNumber: number;
    }) {
        const dataGridsStore = useDataGridsStore();
        dataGridsStore.dataGrids = {
            ...dataGridsStore.dataGrids,
            [identifier]: {
                ...dataGridsStore.dataGrids[identifier],
                page: pageNumber,
            },
        };
    },
    [SET_PAGINATION]({ identifier, pagination }: {
        identifier: string;
        pagination: PaginationResponse;
    }) {
        const dataGridsStore = useDataGridsStore();
        dataGridsStore.dataGrids = {
            ...dataGridsStore.dataGrids,
            [identifier]: {
                ...dataGridsStore.dataGrids[identifier],
                pagination: {
                    ...pagination,
                },
            },
        };
    },
    [SET_PARAMETERS]({ identifier, parameters }: {
        identifier: string;
        parameters: Record<string, string | number>;
    }) {
        const dataGridsStore = useDataGridsStore();
        dataGridsStore.dataGrids = {
            ...dataGridsStore.dataGrids,
            [identifier]: {
                ...dataGridsStore.dataGrids[identifier],
                parameters,
            },
        };
    },
    [SET_PER_PAGE]({ identifier, perPage }: {
        identifier: string;
        perPage: number;
    }) {
        const dataGridsStore = useDataGridsStore();
        dataGridsStore.dataGrids = {
            ...dataGridsStore.dataGrids,
            [identifier]: {
                ...dataGridsStore.dataGrids[identifier],
                perPage,
            },
        };
    },
    [SET_QUERY_STRING]({ identifier, queryString }: {
        identifier: string;
        queryString: string | null;
    }) {
        const dataGridsStore = useDataGridsStore();
        dataGridsStore.dataGrids = {
            ...dataGridsStore.dataGrids,
            [identifier]: {
                ...dataGridsStore.dataGrids[identifier],
                queryString,
            },
        };
    },
    [SET_SORT_DESCENDING]({ identifier, sortDescending }: {
        identifier: string;
        sortDescending: number | null;
    }) {
        const dataGridsStore = useDataGridsStore();
        dataGridsStore.dataGrids = {
            ...dataGridsStore.dataGrids,
            [identifier]: {
                ...dataGridsStore.dataGrids[identifier],
                sortDescending,
            },
        };
    },
};

export default Mutations;
