import { BaseFactory } from './BaseFactory';
import type { ReadDetailSignupDto } from '~/types/Signup';
import { DetailSignup } from '~/models/DetailSignup';

export class DetailSignupFactory extends BaseFactory<ReadDetailSignupDto, DetailSignup> {
    /* eslint-disable-next-line max-statements */
    public toModel(dto: ReadDetailSignupDto): DetailSignup {
        const model = new DetailSignup();

        model.companyData = dto.companyData;
        model.companyId = dto.companyId;
        model.requirements = dto.requirements;
        model.rejectExplanation = dto.rejectExplanation;
        model.relation = dto.relation;
        model.relationData = dto.relationData;
        model.relationId = dto.relationId;
        model.signoutCertificateId = dto.signoutCertificateId;
        model.finished = dto.finished;
        model.id = dto.id;
        model.status = dto.status;
        model.loggedIn = dto.loggedIn;
        model.signout = dto.signout;
        model.type = dto.type;
        model.uniqueNumber = dto.uniqueNumber;
        model.uuid = dto.uuid;
        model.vogDocument = dto.vogDocument;
        model.svmnivoDocument = dto.svmnivoDocument;
        model.skoDocument = dto.skoDocument;
        model.firstOptionalDocument = dto.firstOptionalDocument;
        model.secondOptionalDocument = dto.secondOptionalDocument;

        return model;
    }
}
