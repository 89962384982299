/* eslint-disable max-classes-per-file */

import type { ChamberRegistrationType } from '~/models/ChamberRegistrationType';

export class BaseExtranetNotification {
    public text: string | null = null;
}

export class ExtranetNotification extends BaseExtranetNotification {}

export class CrmExtranetNotification extends BaseExtranetNotification {
    public id: number | null = null;

    public name: string | null = null;

    public dateFrom: string | null = null;

    public dateTill: string | null = null;

    public registrationTypes: ChamberRegistrationType[] = [];

    public getFormattedRegistrationTypes(): string {
        if (!this.registrationTypes) {
            return '';
        }

        return this.registrationTypes.map(registrationType => registrationType.name).join(', ');
    }
}
