import { type FetchError } from 'ofetch';
import BaseApiService from '~/services/api/BaseApiService';
import { apiErrorHandler } from '~/utils/forms/ErrorHandling';
import type { List } from '~/models/List';
import type { ListResponse } from '~/types/List';
import { ListFactory } from '~/models/factories/ListFactory';
import type { Product } from '~/models/Product';
import type {
    ProductResponse,
    ProductsOverview,
    ProductsOverviewResponse,
    WriteProductDto,
} from '~/types/Product';
import { ProductFactory } from '~/models/factories/ProductFactory';

export default class ProductService extends BaseApiService {
    private basePath = 'products';

    /**
     * @description Fetch single product by product id.
     * @param {number} productId Identifier of the product to fetch
     * @returns {Promise<Product>} Promise with the Product model as payload
     */
    async fetchProduct(productId: number): Promise<Product> {
        try {
            const response = await this.baseGet<ProductResponse>(
                `${this.createPath(this.basePath)}/${productId}`,
            );

            return (new ProductFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch multiple products depending on values in queryString.
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<ProductsOverview>} Promise with the ProductsOverview as payload
     */
    async fetchProducts(queryParameters: (Record<string, unknown> | null) = null): Promise<ProductsOverview> {
        try {
            const response = await this.baseGet<ProductsOverviewResponse>(
                this.createPath(this.basePath),
                queryParameters,
            );

            const data = (new ProductFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch list of products.
     * @returns {Promise<List[]>} Promise with the List as payload
     */
    async fetchProductsList(): Promise<List[]> {
        try {
            const response = await this.baseGet<ListResponse>(
                `${this.createPath(this.basePath)}/list`,
            );

            return (new ListFactory()).toModels(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Create a new product.
     * @param {WriteProductDto} data Form data to post
     * @returns {Promise<Product>} Api response
     */
    async create(data: WriteProductDto): Promise<Product> {
        try {
            const response = await this.basePost<ProductResponse>(
                this.createPath(this.basePath),
                data,
            );

            return (new ProductFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Update a product.
     * @param {number} productId Id of the resource to save
     * @param {WriteProductDto} data Form data to post
     * @returns {Promise<null>} Api response
     */
    update(productId: number, data: WriteProductDto): Promise<null> {
        try {
            return this.basePatch<null>(
                `${this.createPath(this.basePath)}/${productId}`,
                data,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Delete a product.
     * @param {number} productId Id of the resource to delete
     * @returns {Promise<null>} Api response
     */
    delete(productId: number): Promise<null> {
        try {
            return this.baseDelete(`${this.createPath(this.basePath)}/${productId}`);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }
}
