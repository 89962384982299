import { BaseFactory } from './BaseFactory';
import type { ReadAddressDto } from '~/types/RelationAddress';
import { Address } from '~/models/Address';

export class AddressFactory extends BaseFactory<ReadAddressDto, Address> {
    public toModel(dto: ReadAddressDto): Address {
        const model = new Address();

        model.city = dto.city;
        model.houseNumber = dto.houseNumber;
        model.houseNumberPostfix = dto.houseNumberPostfix;
        model.invoiceAddressType = dto.invoiceAddressType ?? null;
        model.organisation = dto.organisation ?? null;
        model.postal = dto.postal;
        model.salutation = dto.salutation;
        model.street = dto.street;

        return model;
    }
}
