import { SIGNUP_STATUS_LABEL_MAP } from '~/constants/Signup';
import type { Relation } from '~/models/Relation';
import type {
    SignupCompanyData, SignupRegistrationType, SignupRelationData, SignupRequirements, SignupStatus,
} from '~/types/Signup';

export class DetailSignup {
    public companyData: SignupCompanyData | null = null;

    public companyId: number | null = null;

    public requirements: SignupRequirements | null = null;

    public rejectExplanation: string | null = null;

    public relation: Relation | null = null;

    public relationData: SignupRelationData | null = null;

    public relationId: number | null = null;

    public signoutCertificateId: number | null = null;

    public finished: boolean | null = null;

    public id: number | null = null;

    public status: SignupStatus | null = null;

    public loggedIn: boolean | null = null;

    public signout: boolean | null = null;

    public type: SignupRegistrationType | null = null;

    public uniqueNumber: string | null = null;

    public uuid: string | null = null;

    public vogDocument: string | null = null;

    public svmnivoDocument: string | null = null;

    public skoDocument: string | null = null;

    public firstOptionalDocument: string | null = null;

    public secondOptionalDocument: string | null = null;

    public statusLabel(): string {
        if (!this.status) {
            return '';
        }

        return SIGNUP_STATUS_LABEL_MAP[this.status];
    }
}
