/* eslint-disable unicorn/filename-case */
export const SET_DATA_GRID = 'SET_DATA_GRID';
export const SET_FILTER = 'SET_FILTER';
export const SET_FILTERS_CHANGED = 'SET_FILTERS_CHANGED';
export const SET_ITEMS = 'SET_ITEMS';
export const SET_LOADING = 'SET_LOADING';
export const SET_ORDER_BY = 'SET_ORDER_BY';
export const SET_PAGE = 'SET_PAGE';
export const SET_PAGINATION = 'SET_PAGINATION';
export const SET_PARAMETERS = 'SET_PARAMETERS';
export const SET_PER_PAGE = 'SET_PER_PAGE';
export const SET_SORT_DESCENDING = 'SET_SORT_DESCENDING';
export const SET_QUERY_STRING = 'SET_QUERY_STRING';
