import {
    GET_FILTERS,
    GET_ORDER_BY,
    GET_PAGE,
    GET_PARAMETERS,
    GET_PER_PAGE,
    GET_QUERY_STRING,
    GET_SORT_DESCENDING,
} from './-getter-types';
import {
    SET_DATA_GRID,
    SET_FILTER,
    SET_FILTERS_CHANGED,
    SET_ITEMS,
    SET_LOADING,
    SET_ORDER_BY,
    SET_PAGE,
    SET_PAGINATION,
    SET_PARAMETERS,
    SET_PER_PAGE,
    SET_QUERY_STRING,
    SET_SORT_DESCENDING,
} from './-mutation-types';
import { camelToSnake } from '~/utils/TextUtils';
import { useDataGridsStore } from '~/stores/data-grids';
import FetchActions from '~/stores/data-grids/-fetch-actions';
import type { PaginationResponse } from '~/types/Meta';

type DataGridActionArguments = {
    identifier: string;
    parameters?: Record<string, unknown>;
};

const Actions = {
    // eslint-disable-next-line max-statements
    async fetchItems({ identifier }: DataGridActionArguments & Record<string, unknown>) {
        const dataGridsStore = useDataGridsStore();
        if (Object.keys(FetchActions).includes(`for${identifier}`)) {
            dataGridsStore.setLoading({
                identifier,
                loading: true,
            });

            const queryParameters = dataGridsStore.getQueryParameters({
                identifier,
            });

            const parameters = dataGridsStore[GET_PARAMETERS](identifier);
            const dataGridKey = `for${identifier}`;
            const actionToExecute = dataGridsStore[dataGridKey as keyof typeof FetchActions];

            if (
                !(dataGridKey in dataGridsStore) ||
                typeof actionToExecute !== 'function'
            ) {
                throw new Error(`The data grid key "${dataGridKey}" does not exist`);
            }

            const response = await actionToExecute({
                // @ts-expect-error Unfortunately, the parameters are not typed
                parameters,
                queryParameters,
            });

            dataGridsStore.setItems({
                identifier,
                items: response.data,
            });

            if (
                // eslint-disable-next-line no-undefined
                response.meta !== undefined &&
                response.meta !== null
            ) {
                dataGridsStore.setPagination({
                    identifier,
                    pagination: response.meta.pagination,
                });
            }

            dataGridsStore.setLoading({
                identifier,
                loading: false,
            });
        }
    },
    /* eslint-disable-next-line max-statements */
    getQueryParameters({ identifier }: DataGridActionArguments) {
        const dataGridsStore = useDataGridsStore();
        const queryParameters: {
            [key: string]: string | string[] | number | null | {[key: string]: string | string[] | number | null };
        } = {
            page: dataGridsStore[GET_PAGE](identifier),
            recordsPerPage: dataGridsStore[GET_PER_PAGE](identifier),
        };

        const queryString = dataGridsStore[GET_QUERY_STRING](identifier);
        if (queryString) {
            queryParameters.queryString = queryString;
        }

        const orderBy = dataGridsStore[GET_ORDER_BY](identifier);
        if (orderBy) {
            // eslint-disable-next-line unicorn/prefer-ternary
            if (Array.isArray(orderBy)) {
                queryParameters.orderBy = orderBy.map(orderByKey => camelToSnake(orderByKey));
            } else {
                queryParameters.orderBy = camelToSnake(orderBy);
            }
            queryParameters.sortDesc = dataGridsStore[GET_SORT_DESCENDING](identifier);
        }

        if (dataGridsStore[GET_FILTERS](identifier)) {
            queryParameters.filters = dataGridsStore[GET_FILTERS](identifier);
        }

        return queryParameters;
    },
    setDataGrid(
        { identifier, parameters }: DataGridActionArguments & { parameters?: Record<string, string | number> },
    ) {
        const dataGridsStore = useDataGridsStore();
        dataGridsStore[SET_DATA_GRID](identifier);
        if (parameters) {
            dataGridsStore[SET_PARAMETERS]({
                identifier,
                parameters,
            });
        }
    },
    setFilter({ filterProperty, filterValue, identifier }: DataGridActionArguments & {
        filterProperty: string;
        filterValue: string | number;
    }) {
        const dataGridsStore = useDataGridsStore();
        dataGridsStore[SET_FILTER]({
            filterProperty,
            filterValue,
            identifier,
        });

        dataGridsStore[SET_FILTERS_CHANGED](identifier);
    },
    setItems({ identifier, items }: DataGridActionArguments & {
        items: unknown;
    }) {
        const dataGridsStore = useDataGridsStore();
        dataGridsStore[SET_ITEMS]({
            identifier,
            items,
        });
    },
    setLoading({ identifier, loading }: DataGridActionArguments & {
        loading: boolean;
    }) {
        const dataGridsStore = useDataGridsStore();
        dataGridsStore[SET_LOADING]({
            identifier,
            loading,
        });
    },
    setPage({ identifier, pageNumber }: DataGridActionArguments & {
        pageNumber: number;
    }) {
        const dataGridsStore = useDataGridsStore();
        dataGridsStore[SET_PAGE]({
            identifier,
            pageNumber,
        });
        dataGridsStore[SET_FILTERS_CHANGED](identifier);
    },
    setPagination({ identifier, pagination }: DataGridActionArguments & {
        pagination: PaginationResponse;
    }) {
        const dataGridsStore = useDataGridsStore();
        dataGridsStore[SET_PAGINATION]({
            identifier,
            pagination,
        });
    },
    setPerPage({ identifier, perPage }: DataGridActionArguments & {
        perPage: number;
    }) {
        const dataGridsStore = useDataGridsStore();
        dataGridsStore[SET_PAGE]({
            identifier,
            pageNumber: 1,
        });
        dataGridsStore[SET_PER_PAGE]({
            identifier,
            perPage,
        });
        dataGridsStore[SET_FILTERS_CHANGED](identifier);
    },
    setQueryString({ identifier, queryString }: DataGridActionArguments & {
        queryString: string;
    }) {
        const dataGridsStore = useDataGridsStore();
        dataGridsStore[SET_PAGE]({
            identifier,
            pageNumber: 1,
        });
        dataGridsStore[SET_QUERY_STRING]({
            identifier,
            queryString,
        });
        dataGridsStore[SET_FILTERS_CHANGED](identifier);
    },
    setSorting(
        {
            identifier,
            orderBy,
            sortDescending,
        }: DataGridActionArguments & {
            orderBy: string | string[];
            sortDescending: number | null;
        },
    ) {
        const dataGridsStore = useDataGridsStore();
        if (dataGridsStore[GET_ORDER_BY](identifier) !== orderBy) {
            dataGridsStore[SET_ORDER_BY]({
                identifier,
                orderBy,
            });
        }

        if (dataGridsStore[GET_SORT_DESCENDING](identifier) !== sortDescending) {
            dataGridsStore[SET_SORT_DESCENDING]({
                identifier,
                sortDescending,
            });
        }

        dataGridsStore[SET_FILTERS_CHANGED](identifier);
    },
};

export default Actions;
