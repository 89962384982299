/* eslint-disable max-classes-per-file */
import type { InvoiceLine } from './InvoiceLine';
import type { InvoiceLog } from './InvoiceLog';
import type { InvoiceStatus } from './InvoiceStatus';
import type { InvoiceType } from './InvoiceType';
import type { Relation } from './Relation';
import DateFormatUtils from '~/utils/DateFormatUtils';
import type { AddressTypeEnum } from '~/enums/AddressTypeEnum';
import type { ExtranetInvoiceStatus } from '~/types/Invoice';

class BaseInvoice {
    public dateSent: string | null = null;

    public description: string | null = null;

    public id: number | null = null;

    public invoiceNumber: number | null = null;

    public getDateSent(): string {
        return DateFormatUtils.dateFormatToDisplay(this?.dateSent?.split(' ')[0] ?? '');
    }
}

export class Invoice extends BaseInvoice {
    public accountingPeriod: number | null = null;

    public address: string | null = null;

    public addressType: AddressTypeEnum | null = null;

    public date: string | null = null;

    public daysPastDue: number | null = null;

    public deletedAt: string | null = null;

    public invoiceLines: InvoiceLine[] = [];

    public invoiceLogs: InvoiceLog[] = [];

    public invoiceStatus: InvoiceStatus | null = null;

    public invoiceStatusId: number | null = null;

    public invoiceType: InvoiceType | null = null;

    public invoiceTypeId: number | null = null;

    public relation: Relation | null = null;

    public relationId: number | null = null;

    public reminderSent: string | null = null;

    public sendDate: string | null = null;

    public queuedAt: string | null = null;

    public subtotal: number | null = null;

    public tax: number | null = null;

    public total: number | null = null;

    public getDate(): string {
        return DateFormatUtils.dateFormatToDisplay(this?.date?.split(' ')[0] ?? '');
    }

    public getSendDate(): string {
        return DateFormatUtils.dateFormatToDisplay(this?.sendDate?.split(' ')[0] ?? '');
    }

    public getReminderSent(): string | null {
        return DateFormatUtils.dateFormatToDisplay(this?.reminderSent?.split(' ')[0] ?? '');
    }

    public getReminderSentString(): string {
        if (this.reminderSent === null) {
            return 'Nee';
        }

        return `Ja - ${this.getReminderSent()}`;
    }
}

export class ExtranetInvoice extends BaseInvoice {
    public paymentLink: string | null = null;

    public totalAmount: string | null = null;

    public status: ExtranetInvoiceStatus | null = null;
}
