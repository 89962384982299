import { type FetchError } from 'ofetch';
import BaseApiService from '~/services/api/BaseApiService';
import { apiErrorHandler } from '~/utils/forms/ErrorHandling';
import type {
    ChamberResponse, ChambersOverview, ChambersOverviewResponse, WriteChamberDto,
} from '~/types/Chamber';
import { ChamberFactory } from '~/models/factories/ChamberFactory';
import type { Chamber } from '~/models/Chamber';
import { ChamberListFactory } from '~/models/factories/ChamberListFactory';
import type { ChamberList } from '~/models/ChamberList';
import type { ChamberListResponse } from '~/types/ChamberList';

export default class ChamberService extends BaseApiService {
    private basePath = 'chambers';

    /**
     * @description Fetch single chamber by chamber id.
     * @param {number} chamberId Identifier of the chamber to fetch
     * @returns {Promise<Chamber>} Promise with the Chamber model as payload
     */
    async fetchChamber(chamberId: number): Promise<Chamber> {
        try {
            const response = await this.baseGet<ChamberResponse>(
                `${this.createPath(this.basePath)}/${chamberId}`,
            );

            return (new ChamberFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch multiple chambers depending on values in queryString.
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<ChambersOverview>} Promise with the ChambersOverview as payload
     */
    async fetchChambers(queryParameters: (Record<string, unknown> | null) = null): Promise<ChambersOverview> {
        try {
            const response = await this.baseGet<ChambersOverviewResponse>(
                this.createPath(this.basePath),
                queryParameters,
            );

            const data = (new ChamberFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch list of chambers.
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<ChamberList[]>} Promise with the ChamberList as payload
     */
    async fetchChambersList(queryParameters: (Record<string, unknown> | null) = null): Promise<ChamberList[]> {
        try {
            const response = await this.baseGet<ChamberListResponse>(
                `${this.createPath(this.basePath)}/list`,
                queryParameters,
            );

            return (new ChamberListFactory()).toModels(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Create a new chamber.
     * @param {WriteChamberDto} data Form data to post
     * @returns {Promise<Chamber>} Api response
     */
    async create(data: WriteChamberDto): Promise<Chamber> {
        const response = await this.basePost<ChamberResponse>(
            this.createPath(this.basePath),
            data,
        );

        return (new ChamberFactory()).toModel(response.data);
    }

    /**
     * @description Update a chamber.
     * @param {number} chamberId Id of the resource to save
     * @param {WriteChamberDto} data Form data to post
     * @returns {Promise<null>} Api response
     */
    update(chamberId: number, data: WriteChamberDto): Promise<null> {
        return this.basePatch<null>(
            `${this.createPath(this.basePath)}/${chamberId}`,
            data,
        );
    }

    /**
     * @description Delete a chamber.
     * @param {number} chamberId Id of the resource to delete
     * @returns {Promise<null>} Api response
     */
    delete(chamberId: number): Promise<null> {
        try {
            return this.baseDelete(`${this.createPath(this.basePath)}/${chamberId}`);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }
}
