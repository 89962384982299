import { BaseFactory } from './BaseFactory';
import type { ReadRemarkDto } from '~/types/Remark';
import { Remark } from '~/models/Remark';

export class RemarkFactory extends BaseFactory<ReadRemarkDto, Remark> {
    public toModel(dto: ReadRemarkDto): Remark {
        const model = new Remark();

        model.createdAt = dto.createdAt;
        model.description = dto.description;
        model.id = Number(dto.id);
        model.important = dto.important;
        model.typeId = dto.typeId ? Number(dto.typeId) : null;
        model.updatedAt = dto.updatedAt;
        model.user = dto.user ?? null;

        return model;
    }
}
