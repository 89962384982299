import type { SignupStatus } from '~/types/Signup';

export const SIGNUP_CHECK_NUMBER_COOKIE_NAME = 'signup_check_number';

export const SIGNUP_STATUS_LABEL_MAP: Record<SignupStatus, string> = {
    /* eslint-disable vue/sort-keys */
    new: 'Nieuw',

    // eslint-disable-next-line camelcase
    in_progress: 'In behandeling',
    approved: 'Goedgekeurd',
    rejected: 'Afgekeurd',
    /* eslint-enable vue/sort-keys */
};
