/* eslint-disable vue/sort-keys */

import { defineStore } from 'pinia';
import { DefaultState } from './data-grids/state';
import Getters from './data-grids/getters';
import Actions from './data-grids/actions';
import Mutations from './data-grids/mutations';
import FetchActions from './data-grids/-fetch-actions';

export const useDataGridsStore = defineStore(
    'data-grids',
    {
        state: DefaultState,
        getters: Getters,
        actions: {
            ...Actions,
            ...FetchActions,
            ...Mutations,
        },
    },
);
