import { SIGNUP_STATUS_LABEL_MAP } from '~/constants/Signup';
import type { SignupRegistrationType, SignupStatus } from '~/types/Signup';
import DateFormatUtils from '~/utils/DateFormatUtils';

export class OverviewSignup {
    public companyName: string | null = null;

    public dateOfBirth: string | null = null;

    public finished: boolean | null = null;

    public gender: string | null = null;

    public id: number | null = null;

    public initials: string | null = null;

    public lastName: string | null = null;

    public status: SignupStatus | null = null;

    public type: SignupRegistrationType | null = null;

    public uniqueNumber: string | null = null;

    public uuid: string | null = null;

    public statusLabel(): string {
        if (!this.status) {
            return '';
        }

        return SIGNUP_STATUS_LABEL_MAP[this.status];
    }

    public getDateOfBirth(): string {
        return DateFormatUtils.dateFormatToDisplay(this?.dateOfBirth?.split(' ')[0] ?? '');
    }
}
