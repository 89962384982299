import { BaseFactory } from './BaseFactory';
import { CertificateStateFactory } from './CertificateStateFactory';
import { ChamberFactory } from './ChamberFactory';
import { RelationFactory } from './RelationFactory';
import { RemarkFactory } from './RemarkFactory';
import { Registration } from '~/models/Registration';
import type { ReadRegistrationDto } from '~/types/Registration';

export class RegistrationFactory extends BaseFactory<ReadRegistrationDto, Registration> {
    /* eslint-disable-next-line max-statements */
    public toModel(dto: ReadRegistrationDto): Registration {
        const model = new Registration();

        model.certificateNumber = dto.certificateNumber;

        if (dto.certificateState) {
            model.certificateState = (new CertificateStateFactory()).toModel(dto.certificateState.data);
        }

        if (dto.chamber) {
            model.chamber = (new ChamberFactory()).toModel(dto.chamber.data);
        }

        model.fullStatus = dto.fullStatus;
        model.id = Number(dto.id);
        model.endDate = dto.endDate;

        if (dto.relation) {
            model.relation = (new RelationFactory()).toModel(dto.relation.data);
        }

        if (dto.remarks) {
            model.remarks = (new RemarkFactory()).toModels(dto.remarks.data);
        }

        model.startDate = dto.startDate;

        return model;
    }
}
