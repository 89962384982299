export class TradeOrganisation {
    public id: number | null = null;

    public endDate: string | null = null;

    public name: string | null = null;

    public shortName: string | null = null;

    public startDate: string | null = null;
}
