import { type FetchError } from 'ofetch';
import type { Chamber } from '~/models/Chamber';
import type { DetailSignup } from '~/models/DetailSignup';
import type { Diploma } from '~/models/Diploma';
import type { SignupCertificate } from '~/models/SignupCertificate';
import { SignupCertificateFactory } from '~/models/SignupCertificateFactory';
import type { TradeOrganisation } from '~/models/TradeOrganisation';
import { ChamberFactory } from '~/models/factories/ChamberFactory';
import { DetailSignupFactory } from '~/models/factories/DetailSignupFactory';
import { DiplomaFactory } from '~/models/factories/DiplomaFactory';
import { OverviewSignupFactory } from '~/models/factories/OverviewSignupFactory';
import { TradeOrganisationFactory } from '~/models/factories/TradeOrganisationFactory';
import BaseApiService from '~/services/api/BaseApiService';
import { apiErrorHandler } from '~/utils/forms/ErrorHandling';
import type { SignupCertificatesResponse } from '~/types/Certificate';
import type { ChambersResponse } from '~/types/Chamber';
import type { DiplomasResponse } from '~/types/Diploma';
import type {
    DetailSignupResponse,
    DownloadSignupDto,
    LinkSignupCompanyDto,
    RejectSignupDto,
    SignupOverviewResponse,
    SignupsOverview,
    UpdateSignupDto,
} from '~/types/Signup';
import type { TradeOrganisationsResponse } from '~/types/TradeOrganisation';
import { enrichFormDataKeyValues } from '~/utils/forms/FormDataUtils';

export default class SignupService extends BaseApiService {
    private basePath = 'signups';

    /**
     * @description Fetch single signup by signup id.
     * @param {number} signupId Identifier of the signup to fetch
     * @returns {Promise<DetailSignup>} Promise with the Signup model as payload
     */
    async fetchSignup(signupId: number): Promise<DetailSignup> {
        try {
            const response = await this.baseGet<DetailSignupResponse>(
                `${this.createPath(this.basePath)}/${signupId}`,
            );

            return (new DetailSignupFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    async fetchSignups(queryParameters: (Record<string, unknown> | null) = null): Promise<SignupsOverview> {
        try {
            const response = await this.baseGet<SignupOverviewResponse>(
                `${this.createPath(this.basePath)}/all`,
                queryParameters,
            );

            const data = (new OverviewSignupFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    async fetchPendingSignups(queryParameters: (Record<string, unknown> | null) = null): Promise<SignupsOverview> {
        try {
            const response = await this.baseGet<SignupOverviewResponse>(
                this.createPath(this.basePath),
                queryParameters,
            );

            const data = (new OverviewSignupFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    async fetchTradeOrganisations(): Promise<TradeOrganisation[]> {
        try {
            const response = await this.baseGet<TradeOrganisationsResponse>(
                `${this.createPath(this.basePath)}/trade-organisations`,
            );

            return (new TradeOrganisationFactory()).toModels(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    async fetchDiplomas(): Promise<Diploma[]> {
        try {
            const response = await this.baseGet<DiplomasResponse>(`${this.createPath(this.basePath)}/diplomas`);

            return (new DiplomaFactory()).toModels(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    async fetchChambers(signupId: number): Promise<Chamber[]> {
        try {
            const response = await this.baseGet<ChambersResponse>(
                `${this.createPath(this.basePath)}/${signupId}/chambers`,
            );

            return (new ChamberFactory()).toModels(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    async download({ document, signupId }: DownloadSignupDto): Promise<void> {
        try {
            await this.baseDownloadBlob(
                `${this.createPath(this.basePath)}/${signupId}/download/${document}`,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    // eslint-disable-next-line max-statements
    async update(dto: UpdateSignupDto): Promise<boolean> {
        try {
            const formData = new FormData();

            enrichFormDataKeyValues({
                formData,
                items: {
                    companyId: dto.companyId,
                    id: dto.id.toString(),
                    relationId: dto.relationId,
                    signoutCertificateId: dto.signoutCertificateId,
                },
            });
            enrichFormDataKeyValues({
                formData,
                items: dto.relationData,
                wrapper: 'relationData',
            });
            enrichFormDataKeyValues({
                exclusions: [
                    'vog',
                    'svmnivo',
                    'sko',
                    'diplomas',
                ],
                formData,
                items: dto.requirements,
                wrapper: 'requirements',
            });

            formData.append('files[vog]', dto.requirements.vog || '');
            formData.append('files[svmnivo]', dto.requirements.svmnivo || '');
            formData.append('files[sko]', dto.requirements.sko || '');

            for (const diploma of dto.requirements.diplomas) {
                formData.append('requirements[diplomas][]', diploma);
            }

            enrichFormDataKeyValues({
                formData,
                items: dto.companyData,
                wrapper: 'companyData',
            });

            await this.basePost(
                `${this.createPath(this.basePath)}/${dto.id}`,
                formData,
            );

            return true;
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    async linkCompany({ companyId, signupId }: LinkSignupCompanyDto): Promise<boolean> {
        try {
            await this.basePost(
                `${this.createPath(this.basePath)}/${signupId}/company`,
                {
                    companyId,
                },
            );

            return true;
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    async createCompany({ companyId, signupId }: LinkSignupCompanyDto): Promise<boolean> {
        try {
            await this.basePost(
                `${this.createPath(this.basePath)}/${signupId}/company`,
                {
                    companyId,
                },
            );

            return true;
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    async approve(signupId: number): Promise<boolean> {
        await this.basePost(
            `${this.createPath(this.basePath)}/${signupId}/approve`,
        );

        return true;
    }

    async reject({ rejectExplanation, signupId }: RejectSignupDto): Promise<boolean> {
        await this.basePost(
            `${this.createPath(this.basePath)}/${signupId}/reject`,
            {
                rejectExplanation,
            },
        );

        return true;
    }

    async fetchCertificates(signupId: number): Promise<SignupCertificate[]> {
        try {
            const response = await this.baseGet<SignupCertificatesResponse>(
                `${this.createPath(this.basePath)}/${signupId}/certificates`,
            );

            return (new SignupCertificateFactory()).toModels(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }
}
