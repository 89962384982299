import { BaseFactory } from '~/models/factories/BaseFactory';
import { CrmExtranetNotification } from '~/models/ExtranetNotification';
import type { CrmReadNotificationDto } from '~/types/ExtranetNotification';
import { ChamberRegistrationTypeFactory } from '~/models/factories/ChamberRegistrationTypeFactory';

export class CrmExtranetNotificationFactory extends BaseFactory<
    CrmReadNotificationDto,
    CrmExtranetNotification
> {
    public toModel(data: CrmReadNotificationDto): CrmExtranetNotification {
        const model = new CrmExtranetNotification();

        model.text = data.text;
        model.name = data.name;
        model.dateFrom = data.dateFrom;
        model.dateTill = data.dateTill;
        model.registrationTypes = (new ChamberRegistrationTypeFactory()).toModels(
            data.registrationTypes.data,
        );
        model.id = data.id;

        return model;
    }
}
