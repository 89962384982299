import { BaseFactory } from './BaseFactory';
import { MailMessageDynamicFieldsFactory } from './MailMessageDynamicFieldsFactory';
import type { PreviewMailMessageDto, ReadMailMessageDto } from '~/types/MailMessage';
import { MailMessage } from '~/models/MailMessage';
import { PreviewMailMessage } from '~/models/PreviewMailMessage';

export class MailMessageFactory extends BaseFactory<ReadMailMessageDto, MailMessage> {
    public toModel(dto: ReadMailMessageDto): MailMessage {
        const model = new MailMessage();

        model.id = Number(dto.id);
        model.mandatoryMail = dto.mandatoryMail;
        model.name = dto.name;
        model.subject = dto.subject;
        model.body = dto.body;
        model.mailTemplateId = Number(dto.mailTemplateId) || null;

        if (dto.dynamicFields) {
            model.dynamicFields = (new MailMessageDynamicFieldsFactory()).toModels(dto.dynamicFields);
        }

        return model;
    }

    public toPreview(dto: PreviewMailMessageDto): PreviewMailMessage {
        const model = new PreviewMailMessage();

        model.preview = dto.preview;

        return model;
    }
}
