import { BaseFactory } from './BaseFactory';
import { CompanyTypeFactory } from './CompanyTypeFactory';
import { RemarkFactory } from './RemarkFactory';
import { Company } from '~/models/Company';
import type { ReadCompanyDto } from '~/types/Company';

export class CompanyFactory extends BaseFactory<ReadCompanyDto, Company> {
    /* eslint-disable-next-line max-statements */
    public toModel(dto: ReadCompanyDto): Company {
        const model = new Company();

        model.active = dto.active;
        model.city = dto.city;
        model.cocNumber = dto.cocNumber;
        if (dto.companyType) {
            model.companyType = (new CompanyTypeFactory()).toModel(dto.companyType.data);
            model.companyTypeId = model.companyType.id;
        }
        model.email = dto.email;
        model.houseNumber = dto.houseNumber;
        model.houseNumberPostfix = dto.houseNumberPostfix;
        model.id = Number(dto.id);
        model.lat = dto.lat;
        model.lng = dto.lng;
        model.mailbox = dto.mailbox;
        model.mailboxCity = dto.mailboxCity;
        model.mailboxPostal = dto.mailboxPostal;
        model.name = dto.name;
        model.phone = dto.phone;
        model.postal = dto.postal;
        model.province = dto.province;

        if (dto.remarks) {
            model.remarks = (new RemarkFactory()).toModels(dto.remarks.data);
        }

        model.street = dto.street;
        model.website = dto.website;

        return model;
    }
}
