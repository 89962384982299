import { BaseFactory } from './BaseFactory';
import type { ReadAttachmentDto } from '~/types/Attachment';
import { Attachment } from '~/models/Attachment';

export class AttachmentFactory extends BaseFactory<ReadAttachmentDto, Attachment> {
    public toModel(dto: ReadAttachmentDto): Attachment {
        const model = new Attachment();

        model.attachedTo = dto.attachedTo;
        model.createdAt = dto.createdAt;
        model.deletedAt = dto.deletedAt;
        model.filename = dto.filename;
        model.id = Number(dto.id);
        model.type = dto.type;
        model.typeName = dto.typeName;
        model.visibleOnExtranet = Boolean(dto.visibleOnExtranet);

        return model;
    }
}
