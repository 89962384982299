import { type FetchError } from 'ofetch';
import BaseApiService from '~/services/api/BaseApiService';
import { apiErrorHandler } from '~/utils/forms/ErrorHandling';
import type { Invoice } from '~/models/Invoice';
import type {
    InvoiceResponse,
    InvoicesOverview,
    InvoicesOverviewResponse,
    ReadInvoiceCountsDto,
    WriteInvoiceBatchDto,
    WriteInvoiceDto,
    WriteInvoiceStatusDto,
} from '~/types/Invoice';
import { InvoiceFactory } from '~/models/factories/InvoiceFactory';
import type { List } from '~/models/List';
import type { ListResponse } from '~/types/List';
import { ListFactory } from '~/models/factories/ListFactory';
import type { RelationsOverview, RelationsOverviewResponse, WriteRelationsBatchDto } from '~/types/Relation';
import { RelationFactory } from '~/models/factories/RelationFactory';
import type { ApiMessageResponse } from '~/types/Api';

export default class InvoiceService extends BaseApiService {
    private basePath = 'invoices';

    /**
     * @description Download pdf by invoice id.
     * @param {number} invoiceId Identifier of the invoice to fetch the pdf of
     * @returns {Promise<Invoice>} Promise with the pdf as payload
     */
    async downloadPdf(invoiceId: number): Promise<void> {
        try {
            await this.baseDownloadBlob(
                `${this.createPath(this.basePath)}/${invoiceId}/pdf`,
            );
        } catch (error) {
            const fetchError = <FetchError>error;

            throw new Error(String(fetchError.statusCode));
        }
    }

    /**
     * @description Fetch single invoice by invoice id.
     * @param {number} invoiceId Identifier of the invoice to fetch
     * @returns {Promise<Invoice>} Promise with the Invoice model as payload
     */
    async fetchInvoice(invoiceId: number): Promise<Invoice> {
        try {
            const response = await this.baseGet<InvoiceResponse>(
                `${this.createPath(this.basePath)}/${invoiceId}`,
            );

            return (new InvoiceFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch multiple invoices depending on values in queryString and the given status.
     * @param {Array} queryParameters Array of GET Parameters
     * @param {string} status status of the invoices to fetch
     * @returns {Promise<InvoicesOverview>} Promise with the InvoicesOverview as payload
     */
    async fetchInvoices(
        queryParameters: (Record<string, unknown> | null) = null,
        status = '',
    ): Promise<InvoicesOverview> {
        try {
            const response = await this.baseGet<InvoicesOverviewResponse>(
                `${this.createPath(this.basePath)}/${status}`,
                queryParameters,
            );

            const data = (new InvoiceFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch multiple relations for annual invoices overview.
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<RelationsOverview>} Promise with the RelationsOverview as payload
     */
    async fetchOverviewAnnualInvoices(
        queryParameters: (Record<string, unknown> | null) = null,
    ): Promise<RelationsOverview> {
        try {
            const response = await this.baseGet<RelationsOverviewResponse>(
                `${this.createPath(this.basePath)}/overview-annual-invoices`,
                queryParameters,
            );

            const data = (new RelationFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch invoice counts per status.
     * @returns {Promise<ReadInvoiceCountsDto>} Promise with the ReadInvoiceCountsDto as payload
     */
    fetchCounts(): Promise<ReadInvoiceCountsDto> {
        try {
            return this.baseGet<ReadInvoiceCountsDto>(
                `${this.createPath(this.basePath)}/invoice-count`,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch list of accounting periods.
     * @returns {Promise<List[]>} Promise with the List as payload
     */
    async fetchAccountingPeriodsList(): Promise<List[]> {
        try {
            const response = await this.baseGet<ListResponse>(
                `${this.createPath(this.basePath)}/list-accounting-periods`,
            );

            return (new ListFactory()).toModels(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Create a new invoice.
     * @param {WriteInvoiceDto} data Form data to post
     * @returns {Promise<Invoice>} Api response
     */
    async create(data: WriteInvoiceDto): Promise<Invoice> {
        try {
            const response = await this.basePost<InvoiceResponse>(
                this.createPath(this.basePath),
                data,
            );

            return (new InvoiceFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Update an invoice.
     * @param {number} invoiceId Id of the resource to save
     * @param {WriteInvoiceDto} data Form data to post
     * @returns {Promise<Invoice>} Api response
     */
    update(invoiceId: number, data: WriteInvoiceDto): Promise<null> {
        try {
            return this.basePatch<null>(
                `${this.createPath(this.basePath)}/${invoiceId}`,
                data,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Update an invoice.
     * @param {number} invoiceId Id of the resource to save
     * @param {WriteInvoiceStatusDto} data Form data to post
     * @returns {Promise<Invoice>} Api response
     */
    updateStatus(invoiceId: number, data: WriteInvoiceStatusDto): Promise<ApiMessageResponse> {
        try {
            return this.basePatch<ApiMessageResponse>(
                `${this.createPath(this.basePath)}/${invoiceId}/update-invoice-status`,
                data,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Delete an invoice.
     * @param {number} invoiceId Id of the resource to delete
     * @returns {Promise<Invoice>} Api response
     */
    delete(invoiceId: number): Promise<null> {
        try {
            return this.baseDelete(
                `${this.createPath(this.basePath)}/${invoiceId}/delete`,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Generate annual invoices.
     * @param {WriteRelationsBatchDto} data Form data to post
     * @returns {Promise<string>} Api response
     */
    generateAnnualInvoices(data: WriteRelationsBatchDto): Promise<string> {
        try {
            return this.basePost<string>(
                `${this.createPath(this.basePath)}/generate-annual-invoices`,
                data,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Batch generate given invoices.
     * @param {WriteInvoiceBatchDto} data Form data to post
     * @returns {Promise<string>} Api response
     */
    generateOpenInvoices(data: WriteInvoiceBatchDto): Promise<string> {
        try {
            return this.basePost<string>(
                `${this.createPath(this.basePath)}/generate-open-invoices`,
                data,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }
}
