import { type FetchError } from 'ofetch';
import BaseApiService from '~/services/api/BaseApiService';
import { apiErrorHandler } from '~/utils/forms/ErrorHandling';
import type {
    CompaniesOverview, CompaniesOverviewResponse, CompanyResponse, WriteCompanyDto,
} from '~/types/Company';
import type { Company } from '~/models/Company';
import { CompanyFactory } from '~/models/factories/CompanyFactory';
import type { ListResponse } from '~/types/List';
import type { List } from '~/models/List';
import { ListFactory } from '~/models/factories/ListFactory';
import type { RelationsOverview, RelationsOverviewResponse } from '~/types/Relation';
import { RelationFactory } from '~/models/factories/RelationFactory';

export default class CompanyService extends BaseApiService {
    public static basePath = 'companies';

    /**
     * @description Fetch single company by company id.
     * @param {number} companyId Identifier of the company to fetch
     * @returns {Promise<Company>} Promise with the Company model as payload
     */
    async fetchCompany(companyId: number): Promise<Company> {
        try {
            const response = await this.baseGet<CompanyResponse>(
                `${this.createPath(CompanyService.basePath)}/${companyId}`,
            );

            return (new CompanyFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch multiple companies depending on values in queryString.
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<CompaniesOverview>} Promise with the CompaniesOverview as payload
     */
    async fetchCompanies(queryParameters: (Record<string, unknown> | null) = null): Promise<CompaniesOverview> {
        try {
            const response = await this.baseGet<CompaniesOverviewResponse>(
                this.createPath(CompanyService.basePath),
                queryParameters,
            );
            const companyData = (new CompanyFactory()).toModels(response.data);
            const metaData = response.meta;

            return {
                data: companyData,
                meta: metaData,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch list of companies.
     * @returns {Promise<List[]>} Promise with the List as payload
     */
    async fetchCompanyList(): Promise<List[]> {
        try {
            const response = await this.baseGet<ListResponse>(
                `${this.createPath(CompanyService.basePath)}/list`,
            );

            return (new ListFactory()).toModels(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch multiple relations depending company id.
     * @param {number} companyId Id of the company to fetch te linked relations of
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<RelationsOverview>} Promise with the RelationsOverview as payload
     */
    async fetchLinkedRelations(companyId: number, queryParameters: (Record<string, unknown> | null) = null): Promise<RelationsOverview> {
        try {
            const response = await this.baseGet<RelationsOverviewResponse>(
                `${this.createPath(CompanyService.basePath)}/${companyId}/relations`,
                queryParameters,
            );
            const relationsData = (new RelationFactory()).toModels(response.data);
            const metaData = response.meta;

            return {
                data: relationsData,
                meta: metaData,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Create a new company.
     * @param {WriteCompanyDto} data Form data to post
     * @returns {Promise<Company>} Api response
     */
    async create(data: WriteCompanyDto): Promise<Company> {
        const response = await this.basePost<CompanyResponse>(
            this.createPath(CompanyService.basePath),
            data,
        );

        return (new CompanyFactory()).toModel(response.data);
    }

    /**
     * @description Update a company.
     * @param {number} companyId Id of the resource to save
     * @param {WriteCompanyDto} data Form data to post
     * @returns {Promise<null>} Api response
     */
    update(companyId: number, data: WriteCompanyDto): Promise<null> {
        return this.basePatch<null>(
            `${this.createPath(CompanyService.basePath)}/${companyId}`,
            data,
        );
    }

    /**
     * @description Delete a company.
     * @param {number} companyId Id of the resource to delete
     * @returns {Promise<null>} Api response
     */
    delete(companyId: number): Promise<null> {
        try {
            return this.baseDelete(`${this.createPath(CompanyService.basePath)}/${companyId}`);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }
}
