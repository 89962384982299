import { type FetchError } from 'ofetch';
import BaseApiService from '~/services/api/BaseApiService';
import { apiErrorHandler } from '~/utils/forms/ErrorHandling';
import type { User } from '~/models/User';
import { UserFactory } from '~/models/factories/UserFactory';
import type {
    UserResponse,
    UsersOverview,
    UsersOverviewResponse,
    WriteUserDto,
} from '~/types/User';

export default class UserService extends BaseApiService {
    private basePath = 'users';

    /**
     * @description Fetch single user by user id.
     * @param {number} userId Identifier of the user to fetch
     * @returns {Promise<User>} Promise with the User model as payload
     */
    async fetchUser(userId: number): Promise<User> {
        try {
            const response = await this.baseGet<UserResponse>(
                `${this.createPath(this.basePath)}/${userId}`,
            );

            return (new UserFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch multiple users depending on values in queryString.
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<UsersOverview>} Promise with the UsersOverview as payload
     */
    async fetchUsers(queryParameters: (Record<string, unknown> | null) = null): Promise<UsersOverview> {
        try {
            const response = await this.baseGet<UsersOverviewResponse>(
                this.createPath(this.basePath),
                queryParameters,
            );

            const data = (new UserFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Create a new user.
     * @param {WriteUserDto} data Form data to post
     * @returns {Promise<User>} Api response
     */
    async create(data: WriteUserDto): Promise<User> {
        try {
            const response = await this.basePost<UserResponse>(
                this.createPath(this.basePath),
                data,
            );

            return (new UserFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Update a user.
     * @param {number} userId Id of the resource to save
     * @param {WriteUserDto} data Form data to post
     * @returns {Promise<null>} Api response
     */
    update(userId: number, data: WriteUserDto): Promise<null> {
        try {
            return this.basePatch<null>(
                `${this.createPath(this.basePath)}/${userId}`,
                data,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Delete a user.
     * @param {number} userId Id of the resource to delete
     * @returns {Promise<null>} Api response
     */
    delete(userId: number): Promise<null> {
        try {
            return this.baseDelete(`${this.createPath(this.basePath)}/${userId}`);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }
}
