import { format, isValid, parse } from 'date-fns';

export default {
    dateFormat(dateString: string, formatFrom: string, formatTo: string): string {
        return dateString ? format(parse(dateString, formatFrom, new Date()), formatTo) : '';
    },
    dateFormatToData(dateString: string): string {
        return this.dateFormat(dateString, 'dd-MM-yyyy', 'yyyy-MM-dd');
    },
    dateFormatToDisplay(dateString: string): string {
        return this.dateFormat(dateString, 'yyyy-MM-dd', 'dd-MM-yyyy');
    },
    isValid(date: Date): boolean {
        return isValid(date);
    },
    isValidString(dateString: string): boolean {
        return isValid(this.parseToDate(dateString));
    },
    parseToDate(dateString: string): Date {
        return parse(dateString, 'yyyy-MM-dd', new Date());
    },
    parseToString(date: Date): string {
        return format(date, 'yyyy-MM-dd');
    },
};
