/* eslint-disable unicorn/filename-case */
export const GET_FILTER_VALUE = 'GET_FILTER_VALUE';
export const GET_FILTERS = 'GET_FILTERS';
export const GET_ITEMS = 'GET_ITEMS';
export const GET_LOADING = 'GET_LOADING';
export const GET_ORDER_BY = 'GET_ORDER_BY';
export const GET_PAGE = 'GET_PAGE';
export const GET_PAGINATION = 'GET_PAGINATION';
export const GET_PARAMETERS = 'GET_PARAMETERS';
export const GET_PER_PAGE = 'GET_PER_PAGE';
export const GET_QUERY_STRING = 'GET_QUERY_STRING';
export const GET_SORT_DESCENDING = 'GET_SORT_DESCENDING';
export const HAS_DATAGRID = 'HAS_DATAGRID';
