import { BaseFactory } from './BaseFactory';
import type { ReadExactStatusDto } from '~/types/ExactStatus';
import { ExactStatus } from '~/models/ExactStatus';

export class ExactStatusFactory extends BaseFactory<ReadExactStatusDto, ExactStatus> {
    public toModel(dto: ReadExactStatusDto): ExactStatus {
        const model = new ExactStatus();

        model.accessTokenExpiresAt = dto.accessTokenExpiresAt;
        model.refreshTokenExpiresAt = dto.refreshTokenExpiresAt;
        model.showRefreshButton = Boolean(dto.showRefreshButton);
        model.showReconnectButon = Boolean(dto.showReconnectButon);

        return model;
    }
}
