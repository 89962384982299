import { BaseFactory } from './BaseFactory';
import type { ReadLevelOfMeasurementsStatusTextDto } from '~/types/LevelOfMeasurementsStatusText';
import { LevelOfMeasurementsStatusText } from '~/models/LevelOfMeasurementsStatusText';

export class LevelOfMeasurementsStatusTextFactory
    extends BaseFactory<ReadLevelOfMeasurementsStatusTextDto, LevelOfMeasurementsStatusText> {
    public toModel(dto: ReadLevelOfMeasurementsStatusTextDto): LevelOfMeasurementsStatusText {
        const model = new LevelOfMeasurementsStatusText();

        model.cmsTitle = dto.cmsTitle;
        model.id = Number(dto.id);
        model.key = dto.key;
        model.text = dto.text;
        model.title = dto.title;

        return model;
    }
}
