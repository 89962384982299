import { BaseFactory } from './BaseFactory';
import { RegistrationTypeFactory } from './RegistrationTypeFactory';
import type { ReadDocumentDto } from '~/types/Document';
import { Document } from '~/models/Document';

export class DocumentFactory extends BaseFactory<ReadDocumentDto, Document> {
    public toModel(dto: ReadDocumentDto): Document {
        const model = new Document();

        model.active = dto.active;
        model.id = Number(dto.id);
        model.name = dto.name;
        model.originalName = dto.originalName;

        if (dto.registrationTypes) {
            model.registrationTypes = (new RegistrationTypeFactory()).toModels(dto.registrationTypes.data);
        }

        return model;
    }
}
