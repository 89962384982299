import { type FetchError } from 'ofetch';
import BaseApiService from '~/services/api/BaseApiService';
import { apiErrorHandler } from '~/utils/forms/ErrorHandling';
import type {
    CreateExtranetNotificationDto,
    CrmExtranetNotificationOverview,
    CrmExtranetNotificationOverviewResponse,
    CrmExtranetNotificationResponse,
    DeleteExtranetNotificationDto,
    FetchExtranetNotificationDto,
} from '~/types/ExtranetNotification';
import type { CrmExtranetNotification } from '~/models/ExtranetNotification';
import { CrmExtranetNotificationFactory } from '~/models/factories/CrmExtranetNotificationFactory';

export default class ExtranetNotificationService extends BaseApiService {
    private basePath = 'extranet_notifications';

    async fetchExtranetNotifications(
        queryParameters: (Record<string, unknown> | null) = null,
    ): Promise<CrmExtranetNotificationOverview> {
        try {
            const response = await this.baseGet<CrmExtranetNotificationOverviewResponse>(
                this.createPath(this.basePath),
                queryParameters,
            );

            const { data, meta } = response;

            return {
                data: (new CrmExtranetNotificationFactory()).toModels(data),
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    async fetchExtranetNotification({ id }: FetchExtranetNotificationDto): Promise<CrmExtranetNotification> {
        try {
            const response = await this.baseGet<CrmExtranetNotificationResponse>(
                `${this.createPath(this.basePath)}/${id.toString()}`,
            );

            return (new CrmExtranetNotificationFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    async create(notification: CreateExtranetNotificationDto): Promise<CrmExtranetNotification> {
        try {
            const response = await this.basePost<CrmExtranetNotificationResponse>(
                this.createPath(this.basePath),
                notification,
            );

            return (new CrmExtranetNotificationFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    async update(notification: CrmExtranetNotification): Promise<CrmExtranetNotification> {
        try {
            const response = await this.basePost<CrmExtranetNotificationResponse>(
                `${this.createPath(this.basePath)}/${notification.id?.toString()}`,
                {
                    ...notification,
                },
            );

            return (new CrmExtranetNotificationFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    async delete({ id }: DeleteExtranetNotificationDto): Promise<void> {
        try {
            await this.baseDelete(
                `${this.createPath(this.basePath)}/${id.toString()}`,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }
}
