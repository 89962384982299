import { BaseFactory } from './BaseFactory';
import type { ReadRegistrationTypeDto } from '~/types/RegistrationType';
import { RegistrationType } from '~/models/RegistrationType';

export class RegistrationTypeFactory extends BaseFactory<ReadRegistrationTypeDto, RegistrationType> {
    public toModel(dto: ReadRegistrationTypeDto): RegistrationType {
        const model = new RegistrationType();

        model.durationInYears = Number(dto.durationInYears);
        model.id = Number(dto.id);
        model.name = dto.name;
        model.numberColumn = dto.numberColumn;
        model.numberPrefix = dto.numberPrefix;
        model.recertification = dto.recertification;
        model.type = dto.type;

        return model;
    }
}
