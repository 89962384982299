import { SET_BREADCRUMBS, SET_ROUTE_NAMESPACE } from './-mutation-types';
import type { Breadcrumb } from '~/models/Breadcrumb';
import { useApplicationStore } from '~/stores/application';

const Mutations = {
    [SET_BREADCRUMBS](breadcrumbs: Breadcrumb[]) {
        const applicationStore = useApplicationStore();
        applicationStore.breadcrumbs = breadcrumbs;
    },
    [SET_ROUTE_NAMESPACE](routeNamespace: string) {
        const applicationStore = useApplicationStore();
        applicationStore.routeNamespace = routeNamespace;
    },
};

export default Mutations;
