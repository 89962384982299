export class ExactLog {
    public content: string | null = null;

    public createdAt: string | null = null;

    public id: number | null = null;

    public method: string | null = null;

    public requestUri: string | null = null;

    public responseStatuscode: number | null = null;

    public responseContent: string | null = null;

    public updatedAt: string | null = null;
}
