import { BaseFactory } from './BaseFactory';
import type { ReadThresholdsAndCoursesDto } from '~/types/Chamber';
import { ThresholdAndCourse } from '~/models/ThresholdAndCourse';

export class ThresholdAndCourseFactory extends BaseFactory<ReadThresholdsAndCoursesDto, ThresholdAndCourse> {
    public toModel(dto: ReadThresholdsAndCoursesDto): ThresholdAndCourse {
        const model = new ThresholdAndCourse();

        model.courses = dto.courses;
        model.description = dto.description;
        model.id = Number(dto.id);
        model.key = dto.key;
        model.level = Number(dto.level);
        model.sort = Number(dto.sort);
        model.threshold = Number(dto.threshold);

        return model;
    }
}
