import { type FetchError } from 'ofetch';
import { DocumentFactory } from '~/models/factories/DocumentFactory';
import BaseApiService from '~/services/api/BaseApiService';
import { apiErrorHandler } from '~/utils/forms/ErrorHandling';
import type {
    DocumentsOverview,
    DocumentsOverviewResponse,
} from '~/types/Document';

export default class DocumentService extends BaseApiService {
    private basePath = 'documents';

    /**
     * @description Download document file.
     * @param {number} documentId Identifier of the document to download
     * @returns {Promise<void>} Promise with the document as payload
     */
    async download(documentId: number): Promise<void> {
        try {
            await this.baseDownloadBlob(
                `${this.createPath(this.basePath)}/${documentId}/download`,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch multiple documents depending on values in queryString of logged in user.
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<DocumentsOverview>} Promise with the DocumentsOverview as payload
     */
    async fetchDocuments(queryParameters: (Record<string, unknown> | null) = null): Promise<DocumentsOverview> {
        try {
            const response = await this.baseGet<DocumentsOverviewResponse>(
                this.createPath(this.basePath),
                queryParameters,
            );

            const data = (new DocumentFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }
}
