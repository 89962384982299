import { BaseFactory } from './BaseFactory';
import type { ReadReportDto } from '~/types/Report';
import { Report } from '~/models/Report';

export class ReportFactory extends BaseFactory<ReadReportDto, Report> {
    public toModel(dto: ReadReportDto): Report {
        const model = new Report();

        model.createdAt = dto.createdAt;
        model.file = dto.file;
        model.generated = Boolean(dto.generated);
        model.id = Number(dto.id);
        model.title = dto.title;
        model.type = dto.type;
        model.updatedAt = dto.updatedAt;

        return model;
    }
}
