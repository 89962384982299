import { BaseFactory } from './BaseFactory';
import type { ReadTaxRateDto } from '~/types/TaxRate';
import { TaxRate } from '~/models/TaxRate';

export class TaxRateFactory extends BaseFactory<ReadTaxRateDto, TaxRate> {
    public toModel(dto: ReadTaxRateDto): TaxRate {
        const model = new TaxRate();

        model.exactVatCode = Number(dto.exactVatCode);
        model.id = Number(dto.id);
        model.rate = Number(dto.rate);

        return model;
    }
}
