// Appends the key-value pairs from the given object to the given FormData.
// Mutates the formData object, so no need to return it.
export const enrichFormDataKeyValues: <TItems extends Record<string, unknown>>(options: {
    formData: FormData;
    items: TItems;
    wrapper?: string;
    exclusions?: (keyof TItems)[];

}) => void = ({
    exclusions, formData, items, wrapper,
}) => {
    const itemsEntries = Object.entries(items);

    for (const [key, value] of itemsEntries) {
        if (
            exclusions?.includes(key) ||
            // eslint-disable-next-line no-undefined
            value === undefined ||
            value === null
        ) {
            continue;
        }

        if (Array.isArray(value)) {
            for (const [nestedItem] of value.entries()) {
                formData.append(
                    wrapper ? `${wrapper}[${key}][]` : `${key}[]`,
                    String(nestedItem),
                );
            }

            continue;
        }

        formData.append(
            wrapper ? `${wrapper}[${key}]` : key,
            String(value),
        );
    }
};
