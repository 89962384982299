import { BaseFactory } from './BaseFactory';
import { TaxRateFactory } from './TaxRateFactory';
import type { ReadProductDto } from '~/types/Product';
import { Product } from '~/models/Product';

export class ProductFactory extends BaseFactory<ReadProductDto, Product> {
    public toModel(dto: ReadProductDto): Product {
        const model = new Product();

        model.code = dto.code;
        model.id = Number(dto.id);
        model.ledgerNumber = dto.ledgerNumber;
        model.name = dto.name;
        model.price = dto.price;

        if (dto.taxRate) {
            model.taxRate = (new TaxRateFactory()).toModel(dto.taxRate.data);
        }

        model.taxRateId = Number(dto.taxRateId);

        return model;
    }
}
