import { BaseFactory } from './BaseFactory';
import type { ReadCourseDto } from '~/types/Course';
import { Course } from '~/models/Course';

export class CourseFactory extends BaseFactory<ReadCourseDto, Course> {
    /* eslint-disable-next-line max-statements */
    public toModel(dto: ReadCourseDto): Course {
        const model = new Course();

        model.approved = dto.approved || null;
        model.chamberCodes = dto.chamberCodes || [];
        model.chamberNames = dto.chamberNames;
        model.chambers = dto.chambers || [];
        model.code = dto.code;
        model.courseDate = dto.courseDate || null;
        model.description = dto.description || null;
        model.id = Number(dto.id);
        model.mainChamberId = Number(dto.mainChamberId);
        model.link = dto.link || null;
        model.name = dto.name;
        model.points = Number(dto.points);
        model.sev = dto.sev || null;
        model.source = dto.source || null;
        model.trainingPartners = dto.trainingPartners;
        model.year = Number(dto.year);

        return model;
    }
}
