export class Address {
    public city: string | null = null;

    public houseNumber: string | null = null;

    public houseNumberPostfix: string | null = null;

    public invoiceAddressType: string | null = null;

    public organisation: string | null = null;

    public postal: string | null = null;

    public salutation: string | null = null;

    public street: string | null = null;

    public static stringifyAddress(address: Address, newLineCharacter = '<br>'): string {
        if (!address) {
            return '';
        }

        /* eslint-disable-next-line max-len */
        return `${address.organisation ? `${address.organisation}${newLineCharacter}` : ''}${address.salutation}${newLineCharacter}${address.street ?? ''} ${address.houseNumber ?? ''} ${address.houseNumberPostfix ?? ''}${newLineCharacter}${address.postal ?? ''} ${address.city ?? ''}`.trim();
    }
}
