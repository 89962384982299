import { BaseFactory } from './BaseFactory';
import type { ReadCompanyTypeDto } from '~/types/CompanyType';
import { CompanyType } from '~/models/CompanyType';

export class CompanyTypeFactory extends BaseFactory<ReadCompanyTypeDto, CompanyType> {
    public toModel(dto: ReadCompanyTypeDto): CompanyType {
        const model = new CompanyType();

        model.id = Number(dto.id);
        model.name = dto.name;

        return model;
    }
}
